import React, {useContext, useState} from 'react';
import Container from '@mui/material/Container';
import {Box, Checkbox, Typography} from '@mui/material';
import {getLangStringFromDict, getParagraphs, LangString} from '../../Language/LangString';
import {CTAButton} from '../Button';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {DefaultAppLayout} from '../Layout';
import {checkIfParticipantsExist} from '../../API/apiMethods';
import {LoadingScreen} from '../LoadingScreen';
import {LanguageContext} from '../../Language/LanguageProvider';

const getTextWithLink = (text: string, url: string) => {
    const first = text.split('%href_start%');
    const second = first[1].split('%href_end%');
    return (
        <>
            {first[0]}
            <a href={url} target={'_blank'} rel={'noreferrer'}>
                {second[0]}
            </a>
            {second[1]}
        </>
    );
};
const GDPRComponent: React.FC<{isChecked: boolean; setIsChecked: (isChecked: boolean) => void}> = (p) => {
    const {dictionary} = useContext(LanguageContext);

    const handleCheckMarkClick = () => {
        p.setIsChecked(!p.isChecked);
    };
    const privacyUrl =
        'https://26251149.fs1.hubspotusercontent-eu1.net/hubfs/26251149/documents/Twoday%20-%20Privacy%20Notice.docx.pdf';
    const GDPRText = getTextWithLink(getLangStringFromDict('GDPR_TEXT_MAIN_SURVEY__format', dictionary), privacyUrl);

    return (
        <Box fontSize={14}>
            <p>{getLangStringFromDict('PRIVACY_STATEMENT', dictionary)}</p>
            <p>{GDPRText}</p>
            <Box display={'flex'} alignItems={'flex-start'}>
                <Checkbox onClick={handleCheckMarkClick} checked={p.isChecked} color={'primary'} />
                <Box marginTop={1}>{LangString('GDPR_LABEL_MAIN_SURVEY')}</Box>
            </Box>
        </Box>
    );
};

export const IntroQuestionnaire: React.FC = () => {
    const history = useHistory();
    const {reference} = useParams<{reference: string}>();
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const goToQuestionnaire = () => history.push(`/questionnaire/${reference}/1`);

    return (
        <DefaultAppLayout>
            <Container
                maxWidth={'md'}
                sx={{
                    alignSelf: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 32,

                    '& a': {
                        color: 'primary.main',
                    },
                }}
            >
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                    <Typography variant={'h2'}>{LangString('MAIN_HEADER_TEXT')}</Typography>
                    <Box marginTop={2} marginBottom={2}>
                        {getParagraphs(LangString('INTRO_TEXT_Q'))}
                    </Box>
                    <GDPRComponent isChecked={isChecked} setIsChecked={setIsChecked} />
                    <Box alignSelf={'flex-end'} marginTop={2}>
                        <CTAButton text={LangString('TAKE_SURVEY')} onClick={goToQuestionnaire} disabled={!isChecked} />
                    </Box>
                </Box>
            </Container>
        </DefaultAppLayout>
    );
};

export const IntroQuestionnaireMainParticipant: React.FC = () => {
    const history = useHistory();
    const search = useLocation().search;
    const companyLeadId = new URLSearchParams(search).get('companyLeadId');
    const {dictionary} = useContext(LanguageContext);
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    React.useEffect(() => {
        const fetchData = async () => {
            if (companyLeadId) {
                setIsLoading(true);
                try {
                    const response = await checkIfParticipantsExist(companyLeadId);
                    setIsLoading(false);
                    if (response) {
                        history.replace(`/questionnaire/${response.reference}/1`);
                    }
                } catch (e) {
                    setIsLoading(false);
                }
            }
        };
        fetchData();
    }, [companyLeadId, history]);

    const goToParticipantRegistration = () => history.push(`/registerParticipants/${companyLeadId}`);

    if (isLoading) {
        return (
            <DefaultAppLayout>
                <LoadingScreen />
            </DefaultAppLayout>
        );
    }
    return (
        <DefaultAppLayout>
            <Container
                maxWidth={'md'}
                sx={{
                    alignSelf: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '32px',

                    '& a': {
                        color: 'primary.main',
                    },
                }}
            >
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                    <Typography variant={'h2'}>{getLangStringFromDict('MAIN_HEADER_TEXT', dictionary)}</Typography>
                    <Box marginTop={2} marginBottom={2}>
                        {getParagraphs(getLangStringFromDict('INTRO_TEXT_Q', dictionary))}
                        <br />
                        <GDPRComponent isChecked={isChecked} setIsChecked={setIsChecked} />
                    </Box>
                    <Box alignSelf={'flex-end'} marginTop={3}>
                        <CTAButton
                            text={getLangStringFromDict('REGISTER_PARTICIPANTS', dictionary)}
                            onClick={goToParticipantRegistration}
                            disabled={!isChecked}
                        />
                    </Box>
                </Box>
            </Container>
        </DefaultAppLayout>
    );
};
