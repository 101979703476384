import React from 'react';
import {Translations} from './store/surveyReducer';
import {SupportedLanguage} from './Language/translations';
import {Participant} from './API/DataModelBackend';
import {LanguageContext} from './Language/LanguageProvider';

export const range = (start: number, end: number, length = end - start + 1) =>
    Array.from({length}, (_, i) => start + i);

export function validateEmail(email: string) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function useWindowSize() {
    const [windowSize, setWindowSize] = React.useState<{width: number; height: number}>({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    React.useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

export const getTranslation = (translations: Translations, userLang: SupportedLanguage) =>
    userLang === 'NO' ? translations.textNo : translations.textEn;

export const useTranslation = () => {
    const {userLanguage} = React.useContext(LanguageContext);
    const getTranslation = (translations: Translations) =>
        userLanguage === 'NO' ? translations.textNo : translations.textEn;
    {
    }
};
export const calcCompletedSurveysCount = (participants: Participant[]): number =>
    participants.reduce((acc, curr) => {
        return curr.surveyCompleted ? acc + 1 : acc;
    }, 0);
