import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {SurveyGraphComponent} from './SurveyGraphComponent';
import {Participant, QuestionCategory, QuestionSubCategory, ScoreReferenceIndex} from '../../API/DataModelBackend';

const renderSurveyCategory = (survey: QuestionSubCategory, index: number) => {
    return (
        <Box key={`grid_category_${survey.id}`} sx={{display: 'flex', mb: 1}}>
            <Box sx={{flexBasis: 230}}>
                <Typography>{survey.areaTextNo}</Typography>
            </Box>
            <Box sx={{flex: 1}}>
                <Grid container>
                    {survey.questions.map((question, i) => {
                        return (
                            <Grid key={`grid_category_question_${question.id}`} item xs={12}>
                                <Typography variant={'body2'}>
                                    {index * 5 + i + 1} {question.textNo}
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Box>
    );
};

const renderSurveyCategories = (questionSubCategories: QuestionSubCategory[]): any => {
    return questionSubCategories.map((subCategory, i) => renderSurveyCategory(subCategory, i));
};

export const SurveyComponent: React.FC<{
    filteredParticipants: Participant[];
    categories: QuestionCategory[];
    showVismaIndex: boolean;
    scoreReferenceIndex: ScoreReferenceIndex | undefined;
}> = ({filteredParticipants, categories, showVismaIndex, scoreReferenceIndex}) => {
    return (
        <>
            {categories.map((cat) => (
                <Box key={cat.id}>
                    <Box fontWeight={'bold'} marginTop={2} marginBottom={1}>
                        {cat.perspectiveTextNo}
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            {renderSurveyCategories(cat.questionSubCategories)}
                        </Grid>
                        <Grid item xs={3} sx={{textAlign: 'right', pt: 0.5}}>
                            <SurveyGraphComponent
                                scoreReferenceIndex={scoreReferenceIndex}
                                showReferenceIndex={showVismaIndex}
                                questionCategory={cat}
                                participants={filteredParticipants}
                            />
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </>
    );
};
