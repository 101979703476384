import React, {Dispatch, useContext, useEffect, useState} from 'react';
import {AppBar, Box, Divider, Paper, styled, Theme, useTheme, withStyles} from '@mui/material';
import {CompanyLead} from '../../API/DataModelBackend';
import {AccessContext} from './AdminRoutes';
import {FilterLeadsButton, LeadsFilter} from './FilterLeadsButton';
import {ActionType} from '../../store/actions';

type Lead = {
    companyName: string;
    companyLeadID: number;
    isCurrentLead: boolean;
    status: LeadStatus;
    date: string;
    setCurrentLead: (id: number) => any;
};
const ListItem: React.FC<Lead> = ({companyName, companyLeadID, isCurrentLead, status, date, setCurrentLead}) => {
    const theme = useTheme();
    const handleClick = () => {
        setCurrentLead(companyLeadID);
    };
    return (
        <>
            <Box
                sx={{
                    py: 2,
                    px: 5,
                    backgroundColor: isCurrentLead ? 'rgba(13, 122, 202, 0.1)' : '#171717',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: 'rgba(13, 122, 202, 0.05)',
                    },
                }}
                onClick={handleClick}
            >
                <Box sx={{fontSize: '1.2rem'}}>{companyName}</Box>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    sx={{
                        fontSize: theme.typography.pxToRem(14),
                        color: theme.palette.text.secondary,
                    }}
                >
                    <div>{statusText[status]}</div>
                    <div>{date}</div>
                </Box>
            </Box>
            <Divider />
        </>
    );
};
export type LeadStatus = 'Registered' | 'InitialContact' | 'SurveyEmailSent' | 'SurveyFollowUp' | 'WorkshopCompleted';
export const getLeadStatus = (lead: CompanyLead): LeadStatus => {
    if (!!lead.workshopCompleted) {
        return 'WorkshopCompleted';
    }
    if (!!lead.followUpCompleted) {
        return 'SurveyFollowUp';
    }
    if (!!lead.surveyEmailSent) {
        return 'SurveyEmailSent';
    }
    if (!!lead.initialContact) {
        return 'InitialContact';
    }
    return 'Registered';
};
export const statusText: Record<LeadStatus, string> = {
    Registered: 'Registrert',
    InitialContact: 'Initiell samtale',
    SurveyFollowUp: 'Oppfølgingssamtale Workshop',
    SurveyEmailSent: 'Spørreskjema sendt',
    WorkshopCompleted: 'Workshop gjennomført',
};

const Wrapper = styled(Paper)({
    maxHeight: '100vh',
    overflowY: 'scroll',
    width: 350,
});

type Props = {
    leads: CompanyLead[];
    currentLeadFilter: LeadsFilter;
    selectedLead: number | undefined;
    setSelectedLead: (id: number) => any;
    dispatch: Dispatch<ActionType>;
};
export const LeadsList: React.FC<Props> = (props) => {
    const [filteredLeadsList, setFilteredLeadsList] = useState<CompanyLead[]>(props.leads);
    const {idTokenClaims} = useContext(AccessContext);

    useEffect(() => {
        if (props.currentLeadFilter === 'MINE') {
            setFilteredLeadsList(
                props.leads.filter((p) => {
                    return idTokenClaims && p.advisorObjectId && p.advisorObjectId === idTokenClaims.oid;
                }),
            );
        } else {
            setFilteredLeadsList(props.leads);
        }
    }, [props.currentLeadFilter, idTokenClaims, props.leads]);
    const listHeight = window.innerHeight - 226;
    return (
        <Wrapper style={{height: listHeight + 'px', overflowY: 'scroll'}}>
            <AppBar position={'sticky'}>
                <Box
                    padding={'8px 12px 8px 40px'}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Box marginBottom={0} fontWeight={'bold'}>
                        Leads
                    </Box>
                    <FilterLeadsButton currentFilter={props.currentLeadFilter} dispatch={props.dispatch} />
                </Box>
            </AppBar>
            {filteredLeadsList.map((lead, i) => (
                <ListItem
                    key={i}
                    companyName={lead.companyName}
                    companyLeadID={lead.companyLeadId}
                    isCurrentLead={props.selectedLead === lead.companyLeadId}
                    setCurrentLead={props.setSelectedLead}
                    status={getLeadStatus(lead)}
                    date={new Date(lead.modified).toLocaleDateString()}
                />
            ))}
        </Wrapper>
    );
};
