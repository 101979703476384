import React, {ChangeEvent, Dispatch, SyntheticEvent, useState} from 'react';
import {Checkbox, FormControlLabel, TextField} from '@mui/material';
import styled from 'styled-components';
import {SubmitButton} from '../Button';
import {LangString} from '../../Language/LangString';
import {useHistory} from 'react-router-dom';
import {RegisterCompanyLeadBE} from '../../API/DataModelBackend';
import {registerUser} from '../../API/apiMethods';
import {validateEmail} from '../../toolbox';
import {ActionType, saveCompanyLeadId} from '../../store/actions';

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
`;
const InputField = styled(TextField)`
    margin: 4px 0;
`;

const PhoneInputField = styled(InputField)`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type='number'] {
        -moz-appearance: textfield; /* Firefox */
    }
`;

export const RegistrationForm: React.FC<{dispatch: Dispatch<ActionType>}> = ({dispatch}) => {
    const [companyName, setCompanyName] = useState<string>('');
    const [personName, setPersonName] = useState<string>('');
    const [position, setPosition] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [phone, setPhone] = useState<string>('');
    const [showEmailErrorHelperText, setShowEmailErrorHelperText] = useState<boolean>(false);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
    const toggleCheckbox = () => {
        setIsCheckboxChecked((checked) => !checked);
    };

    const checkEmailInput = () => {
        setIsEmailValid(validateEmail(email));
    };
    const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        if (!isEmailValid) {
            const isValid = validateEmail(e.target.value);
            setIsEmailValid(isValid);
        }
    };

    const history = useHistory();
    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isEmailValid) {
            const companyLeadInfo: RegisterCompanyLeadBE = {
                companyName,
                contactPersonName: personName,
                contactPersonPosition: position,
                contactPersonEmail: email,
                contactPersonPhoneNumber: phone,
            };
            try {
                const companyLeadId = await registerUser(companyLeadInfo);
                dispatch(saveCompanyLeadId(companyLeadId));
                history.push('/confirmation');
            } catch (e) {}
        } else {
            setShowEmailErrorHelperText(true);
        }
    };

    return (
        <FormContainer onSubmit={handleSubmit}>
            <InputField
                required={true}
                value={companyName}
                size={'small'}
                variant={'outlined'}
                label={LangString('COMPANY_NAME')}
                aria-label={LangString('COMPANY_NAME')}
                onChange={(e) => setCompanyName(e.target.value)}
            />
            <InputField
                required={true}
                value={personName}
                onChange={(e) => setPersonName(e.target.value)}
                size={'small'}
                variant='outlined'
                label={LangString('PERSON_NAME')}
                aria-label={LangString('PERSON_NAME')}
            />
            <InputField
                required={true}
                onChange={(e) => setPosition(e.target.value)}
                value={position}
                size={'small'}
                variant='outlined'
                label={LangString('POSITION')}
                aria-label={LangString('POSITION')}
            />
            <InputField
                required={true}
                onChange={handleEmailInputChange}
                value={email}
                onBlur={checkEmailInput}
                error={!isEmailValid}
                helperText={!isEmailValid && showEmailErrorHelperText ? 'Invalid input' : ''}
                size={'small'}
                label={LangString('EMAIL')}
                variant='outlined'
                aria-label={LangString('EMAIL')}
            />
            <PhoneInputField
                required={true}
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                size={'small'}
                label={LangString('PHONE')}
                variant='outlined'
                type='number'
                aria-label={LangString('PHONE')}
            />
            <FormControlLabel
                control={
                    <Checkbox checked={isCheckboxChecked} color={'primary'} onChange={toggleCheckbox} required={true} />
                }
                label={LangString('GDPR_LABEL')}
                componentsProps={{typography: {sx: {mt: 1}}}}
                sx={{mt: 3, mb: 5, alignItems: 'flex-start'}}
            />
            <SubmitButton text={LangString('SUBMIT_CONTACT_FORM_BUTTON')} />
        </FormContainer>
    );
};
