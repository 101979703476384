import React, {useContext, useEffect, useState} from 'react';
import {SurveyComponent} from './SurveyComponent';
import {useHistory, useParams} from 'react-router-dom';
import {getCompanyLeadInfo, getSurvey} from '../../API/apiMethods';
import {CompanyLeadInfoResponse, Participant, Survey} from '../../API/DataModelBackend';
import {
    Box,
    Button,
    Card,
    Checkbox,
    Container,
    FormControlLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import {AccessContext} from './AdminRoutes';
import {ChevronLeftIcon} from '../../Icons/ChevronIcons';
import {LoadingScreen} from '../LoadingScreen';

export const SurveyPage: React.FC<{}> = () => {
    const {accessToken} = useContext(AccessContext);
    const {id} = useParams<{id: string}>();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<{survey: Survey; lead: CompanyLeadInfoResponse}>();

    useEffect(() => {
        const fetchData = async () => {
            const leadId = parseInt(id);
            const leadsInfo = await getCompanyLeadInfo(leadId, accessToken);
            if (leadsInfo) {
                const surveyId = leadsInfo.isPrivateSector ? 2 : 3;
                let surveyResult = await getSurvey(surveyId);
                setData({survey: surveyResult, lead: leadsInfo});
            }
            setIsLoading(false);
        };
        fetchData();
    }, [accessToken, id]);

    if (isLoading || !data) {
        return <LoadingScreen />;
    }
    return <SurveyContent survey={data.survey} lead={data.lead} />;
};
const SurveyContent: React.FC<{survey: Survey; lead: CompanyLeadInfoResponse}> = ({survey, lead}) => {
    const history = useHistory();
    const onNavBack = () => {
        history.push(`/admin/${lead.companyLeadId}`);
    };

    const [selectedParticipant, setSelectedParticipant] = useState<string>('ALL');
    const [filteredParticipants, setFilteredParticipants] = useState<Participant[]>(lead.participants || []);
    useEffect(() => {
        if (lead.participants) {
            if (selectedParticipant === 'ALL') {
                setFilteredParticipants(lead.participants);
            } else {
                setFilteredParticipants(lead.participants.filter((p) => p.email === selectedParticipant));
            }
        }
    }, [lead.participants, selectedParticipant]);

    const [showVismaIndex, setShowVismaIndex] = useState<boolean>(false);
    const handleCheckboxClick = () => {
        setShowVismaIndex(!showVismaIndex);
    };
    const handleParticipantsSelect = (event: SelectChangeEvent) => {
        setSelectedParticipant(event.target.value as string);
    };
    return (
        <Container maxWidth={'xl'}>
            <Button
                onClick={onNavBack}
                variant={'text'}
                style={{fontWeight: 100, fontFamily: 'Ubuntu, sans-serif', fontSize: 22, marginTop: 8}}
                startIcon={<ChevronLeftIcon />}
            >
                {lead.companyName}
            </Button>
            <Card sx={{px: {sm: 2, md: 4, lg: 4, xl: 20}, pt: 1}}>
                <Box display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'} marginBottom={-5}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showVismaIndex}
                                onClick={handleCheckboxClick}
                                color={'primary'}
                                style={{padding: 0}}
                            />
                        }
                        label={'twoday Index'}
                        labelPlacement={'bottom'}
                    />
                    <Select
                        displayEmpty
                        value={selectedParticipant}
                        variant={'outlined'}
                        onChange={handleParticipantsSelect}
                        style={{width: 220, height: 48}}
                    >
                        <MenuItem value={'ALL'}>Alle</MenuItem>
                        {lead.participants &&
                            lead.participants.map((p, i) => (
                                <MenuItem key={`menu_item_${i}`} value={p.email}>
                                    {p.email}
                                </MenuItem>
                            ))}
                    </Select>
                </Box>
                {survey.questionQategories && (
                    <SurveyComponent
                        scoreReferenceIndex={lead.scoreReferenceIndex}
                        filteredParticipants={filteredParticipants}
                        categories={survey.questionQategories}
                        showVismaIndex={showVismaIndex}
                    />
                )}
            </Card>
        </Container>
    );
};
