import React from 'react';
import {MainScoreInfo, State} from '../../store/surveyReducer';
import {Typography, useTheme} from '@mui/material';
import {LanguageContext} from '../../Language/LanguageProvider';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {ResultScoreMainSurvey} from '../ResultScore';
import {getTranslation} from '../../toolbox';
import {DefaultAppLayout} from '../Layout';
import {scoreColors} from '../../theme';
import {getParagraphs, LangString, getLangStringFromDict} from '../../Language/LangString';
import {LoadingScreen} from '../LoadingScreen';
import illustration from '../../assets/image2.png';

export const ScoreInfo: React.FC<{scoreInfo: MainScoreInfo[]}> = ({scoreInfo}) => {
    const {userLanguage, dictionary} = React.useContext(LanguageContext);
    const theme = useTheme();
    const categoryText: Record<string, string> = {
        Configuration: getLangStringFromDict('RESULT_PAGE_CONFIGURATION', dictionary),
        Offering: getLangStringFromDict('RESULT_PAGE_OFFERING', dictionary),
        Experience: getLangStringFromDict('RESULT_PAGE_EXPERIENCE', dictionary),
    };
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                },
            }}
        >
            {scoreInfo.map((s) => (
                <Box
                    key={s.categoryHeaderText.textEn}
                    sx={{
                        mx: 3,
                        maxWidth: 360,
                        flex: '1 0 100px',
                        '&:first-child, &:last-child': {
                            margin: 0,
                        },
                        [theme.breakpoints.down('sm')]: {
                            margin: 0,
                            maxWidth: 'none',
                        },
                    }}
                >
                    <ResultScoreMainSurvey
                        chartSize={80}
                        score={s.scoreNumberPercent}
                        chartColor={scoreColors[s.scoreProfile]}
                        headerText={getTranslation(s.categoryHeaderText, userLanguage)}
                        categoryText={categoryText[s.categoryHeaderText.textEn]}
                        scoreHeaderText={getTranslation(s.scoreText.headerText, userLanguage)}
                        scoreBodyText={getTranslation(s.scoreText.bodyText, userLanguage)}
                    />
                </Box>
            ))}
        </Box>
    );
};

const ResultsContent: React.FC<{scoreInfo: MainScoreInfo[]}> = ({scoreInfo}) => {
    const {dictionary} = React.useContext(LanguageContext);
    const theme = useTheme();
    return (
        <Container maxWidth={'lg'}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid #f3f3f3',
                    mt: 5,
                    pb: 3,
                    '& img': {
                        width: 400,
                    },
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column-reverse',
                        '& img': {
                            width: '100%',
                        },
                    },
                }}
            >
                <Box marginRight={4}>
                    <Typography variant={'h2'}>{getLangStringFromDict('DIGITAL_PROFILE', dictionary)}</Typography>
                    <Box marginTop={3} fontSize={'1.125rem'}>
                        {getParagraphs(LangString('RESULT_PAGE_TEXT'))}
                    </Box>
                </Box>
                <img src={illustration} alt={'Graphical illustration of team work'} />
            </Box>
            <ScoreInfo scoreInfo={scoreInfo} />
        </Container>
    );
};

export const ResultsMainQuestionnaire: React.FC<{store: State}> = ({store}) => {
    let content;
    if (store.scoreInfo === undefined) {
        content = <LoadingScreen text={'Loading score...'} />;
    } else {
        content = <ResultsContent scoreInfo={store.scoreInfo} />;
    }
    return <DefaultAppLayout>{content}</DefaultAppLayout>;
};
