import React, {CSSProperties, useContext} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {getLangStringFromDict, LangString} from '../../Language/LangString';
import {Progress} from '../Progress';
import {Participant} from '../../API/DataModelBackend';
import {LanguageContext} from '../../Language/LanguageProvider';
import {calcCompletedSurveysCount} from '../../toolbox';

type Props = {
    participants: Participant[];
    onShowAnswersClick: () => void;
    onShowScoreClick: () => void;
};
const SmallBtn: React.FC<{onBtnClick: () => void; isDisabled?: boolean; style?: CSSProperties}> = (props) => (
    <Button
        variant={'outlined'}
        color={'primary'}
        size={'small'}
        disabled={props.isDisabled}
        onClick={props.onBtnClick}
        style={props.style}
    >
        {props.children}
    </Button>
);
export const Participants: React.FC<Props> = ({participants, onShowAnswersClick, onShowScoreClick}) => {
    const participantsCount = participants.length;
    const {dictionary} = useContext(LanguageContext);
    const completedSurveys = calcCompletedSurveysCount(participants);

    return (
        <Box display={'flex'} justifyContent={'space-between'}>
            <Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant={'h5'}>{LangString('PARTICIPANTS_QUESTIONNAIRE')}</Typography>
                    <span style={{marginLeft: 24}}>
                        {completedSurveys}/{participantsCount} besvart
                    </span>
                </Box>
                <Progress totalValue={participantsCount} currentValue={completedSurveys} />
                <Box marginTop={3}>
                    {participants.map((p) => (
                        <Box key={p.email} display={'flex'} justifyContent={'space-between'} marginBottom={1}>
                            <span>{p.email}</span>
                            <b>
                                {p.surveyCompleted
                                    ? getLangStringFromDict('ANSWERED', dictionary)
                                    : getLangStringFromDict('NOT_ANSWERED', dictionary)}
                            </b>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box>
                <SmallBtn onBtnClick={onShowScoreClick} isDisabled={completedSurveys === 0} style={{marginRight: 16}}>
                    Se score
                </SmallBtn>
                <SmallBtn onBtnClick={onShowAnswersClick}>Se svar</SmallBtn>
            </Box>
        </Box>
    );
};
