import {
    CompanyLeadInfoResponse,
    CompanyLeadsResponse,
    CompanySurvey,
    ScoreResponse,
    Survey,
    SetSectorBE,
    MainScoreResultResponse,
    StatisticsDto,
    ChangeEmailLanguageDto,
    CompanyTotalScoreDto,
    CompanyLeadId,
} from '../API/DataModelBackend';
import {LeadsFilter} from '../Components/Admin/FilterLeadsButton';

export type ActionType =
    | {type: 'SET_ANSWER'; payload: {qID: number; answer: number}}
    | {type: 'LOAD_QUESTIONNAIRE_SUCCESS'; payload: {questionnaire: CompanySurvey}}
    | {type: 'SAVE_SCORE_ACTION'; payload: {scoreData: ScoreResponse}}
    | {type: 'SAVE_COMPANY_LEAD_ID'; payload: CompanyLeadId}
    | {type: 'SAVE_MAIN_SCORE_RESULT_ACTION'; payload: MainScoreResultResponse}
    | {type: 'LOAD_INITIAL_QUESTIONNAIRE_SUCCESS'; payload: {initialQuestionnaire: Survey}}
    | {type: 'LOAD_COMPANY_LEAD_INFO_SUCCESS'; payload: {leadInfo: CompanyLeadInfoResponse}}
    | {type: 'LOAD_COMPANY_LEADS_SUCCESS'; payload: {leads: CompanyLeadsResponse}}
    | {type: 'REFRESH_COMPANY_LEAD_INFO_SUCCESS'; payload: {leadInfo: CompanyLeadInfoResponse}}
    | {type: 'SET_COMPANY_SECTOR'; payload: {data: SetSectorBE}}
    | {type: 'SET_EMAIL_LANGUAGE'; payload: {data: ChangeEmailLanguageDto}}
    | {type: 'SET_INITIAL_CONTACT_SUCCESS'; payload: string}
    | {type: 'SEND_EMAIL_SUCCESS'; payload: string}
    | {type: 'SET_FOLLOW_UP_CONTACT_SUCCESS'; payload: string}
    | {type: 'SET_WORKSHOP_COMPLETED_SUCCESS'; payload: string}
    | {type: 'SET_LEADS_FILTER'; payload: LeadsFilter}
    | {type: 'LOAD_STATISTICS_SUCCESS'; payload: StatisticsDto}
    | {type: 'LOAD_COMPANY_LEAD_SCORE_INFO'; payload: CompanyTotalScoreDto};

export const loadQuestionnaire = (data: CompanySurvey): ActionType => ({
    type: 'LOAD_QUESTIONNAIRE_SUCCESS',
    payload: {questionnaire: data},
});
export const loadInitialQuestionnaire = (data: Survey): ActionType => ({
    type: 'LOAD_INITIAL_QUESTIONNAIRE_SUCCESS',
    payload: {initialQuestionnaire: data},
});
export const setAnswerAction = (id: number, value: number): ActionType => ({
    type: 'SET_ANSWER',
    payload: {qID: id, answer: value},
});
export const saveCompanyLeadId = (id: CompanyLeadId): ActionType => ({
    type: 'SAVE_COMPANY_LEAD_ID',
    payload: id,
});
export const saveScoreAction = (scoreData: ScoreResponse): ActionType => ({
    type: 'SAVE_SCORE_ACTION',
    payload: {scoreData},
});
export const saveMainScoreResultAction = (scoreData: MainScoreResultResponse): ActionType => ({
    type: 'SAVE_MAIN_SCORE_RESULT_ACTION',
    payload: scoreData,
});
export const loadCompanyLeadsAction = (leads: CompanyLeadsResponse): ActionType => ({
    type: 'LOAD_COMPANY_LEADS_SUCCESS',
    payload: {leads},
});
export const loadCompanyLeadInfoAction = (leadInfo: CompanyLeadInfoResponse): ActionType => ({
    type: 'LOAD_COMPANY_LEAD_INFO_SUCCESS',
    payload: {leadInfo},
});
export const loadCompanyLeadScore = (scoreInfo: CompanyTotalScoreDto): ActionType => ({
    type: 'LOAD_COMPANY_LEAD_SCORE_INFO',
    payload: scoreInfo,
});
export const refreshCompanyLeadInfoAction = (leadInfo: CompanyLeadInfoResponse): ActionType => ({
    type: 'REFRESH_COMPANY_LEAD_INFO_SUCCESS',
    payload: {leadInfo},
});
export const setCompanySectorAction = (data: SetSectorBE): ActionType => ({
    type: 'SET_COMPANY_SECTOR',
    payload: {data},
});
export const setEmailLanguageAction = (data: ChangeEmailLanguageDto): ActionType => ({
    type: 'SET_EMAIL_LANGUAGE',
    payload: {data},
});
export const setInitialContactAction = (timeString: string): ActionType => ({
    type: 'SET_INITIAL_CONTACT_SUCCESS',
    payload: timeString,
});
export const sendEmailAction = (timeString: string): ActionType => ({type: 'SEND_EMAIL_SUCCESS', payload: timeString});
export const setFollowUpContactAction = (timeString: string): ActionType => ({
    type: 'SET_FOLLOW_UP_CONTACT_SUCCESS',
    payload: timeString,
});
export const setWorkshopCompletedAction = (timeString: string): ActionType => ({
    type: 'SET_WORKSHOP_COMPLETED_SUCCESS',
    payload: timeString,
});
export const loadStatisticsAction = (stats: StatisticsDto): ActionType => ({
    type: 'LOAD_STATISTICS_SUCCESS',
    payload: stats,
});
export const setLeadsFilterAction = (filter: LeadsFilter): ActionType => ({type: 'SET_LEADS_FILTER', payload: filter});
