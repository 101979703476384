import {CTAButton} from '../Button';
import React, {Dispatch} from 'react';
import {Question} from './Question';
import {Box, styled, Typography} from '@mui/material';
import {Answers, InitialQuestionnaireProps, QuestionProps, State} from '../../store/surveyReducer';
import {useLanguage} from '../../Language/LangString';
import {useHistory} from 'react-router-dom';
import Container from '@mui/material/Container';
import {getInitialSurvey, sendAnswersInitialSurvey} from '../../API/apiMethods';
import {ActionType, loadInitialQuestionnaire, saveScoreAction, setAnswerAction} from '../../store/actions';
import {AnswersInitialBE, CompanyLeadId} from '../../API/DataModelBackend';
import {DefaultAppLayout} from '../Layout';
import {LoadingScreen} from '../LoadingScreen';

const CContainer = styled(Container)({
    display: 'flex',
    alignItems: 'center',
    marginTop: '32px',
    marginBottom: '32px',
});

type ContentProps = {
    initialQuestionnaire: InitialQuestionnaireProps;
    companyLeadId: CompanyLeadId;
    dispatch: Dispatch<ActionType>;
    answers: Answers;
};
const Content: React.FC<ContentProps> = (props) => {
    const history = useHistory();
    const {getLangString, getTranslation} = useLanguage();
    const getQuestions = () => {
        let questions: QuestionProps[] = [];
        props.initialQuestionnaire.questionGroups.forEach((group) => {
            questions.push(...group.questions);
        });
        return questions;
    };
    const updateAnswer = (id: number, value: number) => {
        props.dispatch(setAnswerAction(id, value));
    };
    const sendAnswers = async () => {
        const answers: AnswersInitialBE = {
            companyLeadId: props.companyLeadId,
            surveyID: props.initialQuestionnaire.surveyID,
            answers: getQuestions().map((q) => ({
                questionId: q.id,
                value: props.answers[q.id]!,
            })),
        };
        try {
            const resp = await sendAnswersInitialSurvey(answers);
            props.dispatch(saveScoreAction(resp));
        } catch (e) {
            console.log('Error!: ', e);
        }
        history.push('resultInitialQ');
    };
    const isAllQuestionsAnswered = Object.keys(props.answers).length === getQuestions().length;

    const questionsElements = () => {
        return getQuestions().map((q, i) => (
            <Question
                key={q.id}
                id={q.id}
                questionNumber={i + 1}
                questionText={getTranslation(q)}
                setAnswer={updateAnswer}
                answer={props.answers[q.id]}
            />
        ));
    };

    return (
        <CContainer maxWidth={'md'}>
            <Box margin={'0 auto'} marginTop={8} maxWidth={780}>
                <Typography variant={'h2'}>{getLangString('DIGITAL_PROFILE')}</Typography>
                {questionsElements()}
                <Box display={'flex'} justifyContent={'flex-end'} marginTop={5}>
                    <CTAButton
                        text={getLangString('CONTINUE')}
                        disabled={!isAllQuestionsAnswered}
                        onClick={sendAnswers}
                    />
                </Box>
            </Box>
        </CContainer>
    );
};

export const InitialQuestionnaire: React.FC<{store: State; dispatch: Dispatch<any>}> = ({store, dispatch}) => {
    const {getLangString} = useLanguage();
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getInitialSurvey();
                dispatch(loadInitialQuestionnaire(result));
            } catch (e) {}
        };
        fetchData();
    }, []);

    let content;
    if (store.initialQuestionnaire === undefined || store.companyLeadID === undefined) {
        content = <LoadingScreen text={getLangString('LOADING_QUESTIONNAIRE') + '...'} />;
    } else {
        content = (
            <Content
                initialQuestionnaire={store.initialQuestionnaire}
                companyLeadId={store.companyLeadID}
                dispatch={dispatch}
                answers={store.answers}
            />
        );
    }
    return <DefaultAppLayout>{content}</DefaultAppLayout>;
};
