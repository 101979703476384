import React from 'react';
import {Box} from '@mui/material';
import {range} from '../toolbox';

type Props = {
    totalValue: number;
    currentValue: number;
};
export const Progress: React.FC<Props> = ({totalValue, currentValue}) => {
    const createProgress = () => {
        return [...range(1, totalValue)].map((n) => (
            <Box
                key={n}
                sx={{
                    backgroundColor: 'accent.main',
                    opacity: n <= currentValue ? 1 : 0.2,
                    height: 8,
                    flexGrow: 1,
                    borderRight: '2px solid',
                    borderColor: '#171717',
                    '&:last-child': {
                        borderRight: 0,
                    },
                }}
            />
        ));
    };
    return <Box display={'flex'}>{createProgress()}</Box>;
};
