import React from 'react';
import {Box, Typography} from '@mui/material';
import {SignInButton} from './SignInButton';
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react';
import {AdminLayout} from '../Layout';
import {AdminRoutes} from './AdminRoutes';

export const Index: React.FC = () => {
    const {inProgress} = useMsal();
    return (
        <AdminLayout>
            <UnauthenticatedTemplate>
                <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flex={1}
                >
                    {inProgress === 'login' ? (
                        <>Logging in</>
                    ) : (
                        <>
                            <Typography sx={{my: 3}} variant={'h3'}>
                                Vennligst logg inn for å fortsette
                            </Typography>
                            <SignInButton />
                        </>
                    )}
                </Box>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <AdminRoutes />
            </AuthenticatedTemplate>
        </AdminLayout>
    );
};
