import React from 'react';
import {Box, Card, styled} from '@mui/material';
import {StatisticsDto} from '../../API/DataModelBackend';

const StatsWrapper = styled(Box)({
    px: 2,
    textAlign: 'center',
});
const StatsValue = styled(Box)({
    fontSize: 24,
    fontFamily: 'Ubuntu, sans-serif',
    fontWeight: 'bold',
});

type StatsProps = {
    headerText: string;
    value1: number;
    subText1?: string;
    value2?: number;
    subText2?: string;
};
const Stats: React.FC<StatsProps> = ({subText1, value1, subText2, value2, headerText}) => {
    if (subText2 && value2) {
        return (
            <Box>
                <Box textAlign={'center'}>{headerText}</Box>
                <Box display={'flex'}>
                    <StatsWrapper>
                        <StatsValue>{value1}</StatsValue>
                        <Box fontSize={'12px'}>{subText1}</Box>
                    </StatsWrapper>
                    <StatsWrapper>
                        <StatsValue color={'#b7b7b7'}>{value2}</StatsValue>
                        <Box fontSize={'12px'}>{subText2}</Box>
                    </StatsWrapper>
                </Box>
            </Box>
        );
    }
    return (
        <Box>
            <Box textAlign={'center'}>{headerText}</Box>
            <StatsWrapper>
                <StatsValue>{value1}</StatsValue>
            </StatsWrapper>
        </Box>
    );
};

export const Statistics: React.FC<{stats: StatisticsDto}> = ({stats}) => {
    return (
        <Card sx={{my: 2, p: 1}}>
            <Box display={'flex'} justifyContent={'space-around'}>
                <Stats headerText={'Nye leads'} value1={stats.newLeads} />
                <Stats headerText={'Kontaktet'} value1={stats.contacted} />
                <Stats
                    headerText={'Utsendte skjema'}
                    subText1={'Bedrifter'}
                    value1={stats.schemaSentCompany}
                    subText2={'Skjema'}
                    value2={stats.schemaSentTotal}
                />
                <Stats
                    headerText={'Antall svar'}
                    subText1={'Bedrifter'}
                    value1={stats.schemaResultReceivedCompany}
                    subText2={'Svar'}
                    value2={stats.sechemaResultReceivedTotal}
                />
                <Stats headerText={'Workshops'} value1={stats.workshops} />
            </Box>
        </Card>
    );
};
