import * as React from 'react';
import {useIsAuthenticated} from '@azure/msal-react';
import {AppBar, Box, Toolbar} from '@mui/material';
import {SignOutButton} from './Admin/SignOutButton';
import {LanguageSelectButton} from './Button';
import {styled} from '@mui/material/styles';

const CustomToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'flex-end',
});

export const AdminLayout: React.FC = ({children}) => {
    const isAuthenticated = useIsAuthenticated();
    return (
        <>
            <AppBar position={'sticky'}>
                <CustomToolbar variant={'dense'}>{isAuthenticated && <SignOutButton />}</CustomToolbar>
            </AppBar>
            <Box flexGrow={'1'} display={'flex'} flexDirection={'column'}>
                {children}
                <Box display={'flex'} justifyContent={'flex-end'}></Box>
            </Box>
        </>
    );
};
export const DefaultAppLayout: React.FC<{overrideFlexDirection?: string}> = ({children, overrideFlexDirection}) => {
    return (
        <>
            <AppBar position={'sticky'}>
                <CustomToolbar variant={'dense'}>
                    <LanguageSelectButton />
                </CustomToolbar>
            </AppBar>
            <Box sx={{flexGrow: '1', display: 'flex', flexDirection: overrideFlexDirection || 'row', mb: 2}}>
                {children}
            </Box>
            <Box alignSelf={'flex-end'}></Box>
        </>
    );
};
