import React, {PropsWithoutRef, useState} from 'react';
import {Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {CompanyLead} from '../../API/DataModelBackend';
import {statusText} from './LeadsList';

interface LeadStatusComponentProps extends PropsWithoutRef<any> {
    active: boolean;
    header: string;
    date: Date | null;
    clickable?: boolean;
    onClick?: () => void;
}

const LeadStatusCard: React.FC<LeadStatusComponentProps> = ({active, header, date, clickable = false, onClick}) => {
    const handleOnClick = () => {
        if (clickable && onClick) {
            onClick();
        }
    };
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                height: '5.5rem',
                width: '100%',
                padding: '8px 12px',
                backgroundColor: active ? 'primary.main' : 'secondary.main',
                color: active ? '#FFFFFF' : '#000000',
                cursor: clickable ? 'pointer' : 'default',
                border: '1px solid transparent',
                '&:hover': {
                    border: clickable ? '1px solid #0D7ACA' : '',
                },
                margin: 0.5,
            }}
            onClick={handleOnClick}
        >
            <Typography variant={'body1'} sx={{fontWeight: 600}}>
                {header}
            </Typography>
            <Box alignSelf={'flex-end'} fontSize={'0.875rem'}>
                {date?.toLocaleDateString('no-NO')}
            </Box>
        </Card>
    );
};

type Props = {
    companyLead: CompanyLead;
    onInitialClicked: () => Promise<void>;
    onFollowUpClicked: () => Promise<void>;
    onWorkshopCompletedClicked: () => Promise<void>;
};
export const LeadStatusComponent: React.FC<Props> = ({
    companyLead,
    onInitialClicked,
    onFollowUpClicked,
    onWorkshopCompletedClicked,
}) => {
    const [dialog, setDialog] = useState<{
        header: string;
        content: string;
        okAction: () => Promise<void>;
    }>();

    const handleOnInitialClicked = () => {
        if (!!companyLead.initialContact) {
            setDialog({
                header: 'Fjerne registrering',
                content: 'Vil du fjerne registrering av initiell samtale?',
                okAction: onInitialClicked,
            });
        } else {
            onInitialClicked();
        }
    };
    const handleOnFollowUpClicked = () => {
        if (!!companyLead.followUpCompleted) {
            setDialog({
                header: 'Fjerne registrering',
                content: 'Vil du fjerne registrering av oppfølgingssamtale?',
                okAction: onFollowUpClicked,
            });
        } else {
            onFollowUpClicked();
        }
    };
    const handleOnWorkshopCompletedClicked = () => {
        if (!!companyLead.workshopCompleted) {
            setDialog({
                header: 'Fjerne registrering',
                content: 'Vil du fjerne registrering av utført workshop?',
                okAction: onWorkshopCompletedClicked,
            });
        } else {
            onWorkshopCompletedClicked();
        }
    };

    return (
        <Box>
            <Box display={'flex'} justifyContent={'space-between'}>
                <LeadStatusCard
                    active={!!companyLead.created}
                    header={statusText['Registered']}
                    clickable={false}
                    date={companyLead.created ? new Date(companyLead.created) : null}
                />
                <LeadStatusCard
                    active={!!companyLead.initialContact}
                    header={statusText['InitialContact']}
                    clickable={
                        !(companyLead.surveyEmailSent || companyLead.followUpCompleted || companyLead.workshopCompleted)
                    }
                    onClick={handleOnInitialClicked}
                    date={companyLead.initialContact ? new Date(companyLead.initialContact) : null}
                />
                <LeadStatusCard
                    active={!!companyLead.surveyEmailSent}
                    header={statusText['SurveyEmailSent']}
                    clickable={false}
                    date={companyLead.surveyEmailSent ? new Date(companyLead.surveyEmailSent) : null}
                />
                <LeadStatusCard
                    active={!!companyLead.followUpCompleted}
                    header={statusText['SurveyFollowUp']}
                    clickable={!!companyLead.surveyEmailSent && !companyLead.workshopCompleted}
                    onClick={handleOnFollowUpClicked}
                    date={companyLead.followUpCompleted ? new Date(companyLead.followUpCompleted) : null}
                />
                <LeadStatusCard
                    active={!!companyLead.workshopCompleted}
                    header={statusText['WorkshopCompleted']}
                    clickable={!!companyLead.followUpCompleted}
                    onClick={handleOnWorkshopCompletedClicked}
                    date={companyLead.workshopCompleted ? new Date(companyLead.workshopCompleted) : null}
                />
            </Box>
            {dialog && (
                <Dialog open={!!dialog}>
                    <DialogTitle>{dialog.header}</DialogTitle>
                    <DialogContent>{dialog.content}</DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialog(undefined)}>Avbryt</Button>
                        <Button
                            onClick={() => {
                                if (dialog.okAction) {
                                    dialog.okAction();
                                }
                                setDialog(undefined);
                            }}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};
