import React from 'react';
import {Box} from '@mui/material';

const Circle = (p: {size: number; strokeWidth: number; score: number; color: string}) => {
    const radius = p.size / 2 - p.strokeWidth / 2;
    const circumference = radius * 2 * Math.PI;
    return (
        <circle
            style={{position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: 'center'}}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={circumference - (p.score / 100) * circumference}
            strokeWidth={`${p.strokeWidth}px`}
            fill={'transparent'}
            stroke={p.color}
            r={radius}
            cx={p.size / 2}
            cy={p.size / 2}
        />
    );
};
export const ScoreChart: React.FC<{
    size: number;
    strokeWidth: number;
    score: number;
    color: string;
    fontSize?: string;
}> = (p) => {
    return (
        <div style={{position: 'relative', width: p.size, height: p.size}}>
            <svg height={p.size} width={p.size} viewBox={`0 0 ${p.size} ${p.size}`}>
                <Circle size={p.size} strokeWidth={p.strokeWidth} score={100} color={'#e0e0e0'} />
                <Circle size={p.size} strokeWidth={p.strokeWidth} score={p.score} color={p.color} />
            </svg>
            <Box
                sx={{
                    fontSize: p.fontSize || '1.5rem',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontFamily: 'Ubuntu, sans-serif',
                    fontWeight: 'bold',
                }}
            >
                {p.score + '%'}
            </Box>
        </div>
    );
};
