import React, {Dispatch, useContext, useEffect} from 'react';
import {Box, Button, Card, Container, Grid, styled, Typography} from '@mui/material';
import {ScoreChart} from '../ScoreChart';
import {scoreColors} from '../../theme';
import {ScoreProfile} from '../../API/DataModelBackend';
import {useHistory, useParams} from 'react-router-dom';
import {getCompanyLeadScore} from '../../API/apiMethods';
import {AccessContext} from './AdminRoutes';
import {LoadingScreen} from '../LoadingScreen';
import {calcCompletedSurveysCount, getTranslation} from '../../toolbox';
import {AdminState, TotalScoreInfo} from '../../store/adminReducer';
import {ActionType, loadCompanyLeadScore} from '../../store/actions';
import {MainScoreInfo} from '../../store/surveyReducer';
import {ScoreInfo} from '../Questionnaire/ResultsMainQuestionnaire';
import {ChevronLeftIcon} from '../../Icons/ChevronIcons';
import {refreshCompanyLead} from './MainPage';

const ScoreContainer = styled(Box)(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '24px 48px',
    marginBottom: 24,
}));
const Score: React.FC<{score: number; scoreProfile: ScoreProfile; text: string; size: number}> = ({
    score,
    scoreProfile,
    text,
    size,
}) => (
    <Box textAlign={'center'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <ScoreChart size={size} strokeWidth={10} score={score} color={scoreColors[scoreProfile]} />
        <Typography style={{marginTop: 8}}>
            <b>{text}</b>
        </Typography>
    </Box>
);
const ScoreCharts: React.FC<{
    totalScore: number;
    totalScoreProfile: ScoreProfile;
    scoreInfoByCategory: MainScoreInfo[];
}> = (props) => {
    return (
        <ScoreContainer>
            <Grid container>
                <Grid item xs={4}>
                    <Score score={props.totalScore} scoreProfile={props.totalScoreProfile} text={'Total'} size={120} />
                </Grid>
                <Grid item xs={8}>
                    <Grid container>
                        {props.scoreInfoByCategory.map((s, i) => (
                            <Grid key={i} item xs={4}>
                                <Score
                                    score={s.scoreNumberPercent}
                                    scoreProfile={s.scoreProfile}
                                    text={getTranslation(s.categoryHeaderText, 'NO')}
                                    size={90}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </ScoreContainer>
    );
};

type ContentProps = {
    companyScoreInfo: TotalScoreInfo;
    indexScoreInfo: TotalScoreInfo;
    completedSurveysCount: number | undefined;
    participantCount: number | undefined;
};
const ScorePageContent: React.FC<ContentProps> = (props) => {
    const date = new Date(Date.now());
    return (
        <Card sx={{px: {sm: 2, md: 4, lg: 4, xl: 20}, py: 4}}>
            <Box>
                {props.completedSurveysCount &&
                    props.participantCount &&
                    `Styrkeprofil basert på svar fra ${props.completedSurveysCount}/${props.participantCount} deltakere`}
                <ScoreCharts
                    totalScore={props.companyScoreInfo.totalScore}
                    totalScoreProfile={props.companyScoreInfo.totalScoreProfile}
                    scoreInfoByCategory={props.companyScoreInfo.scoreByCategory}
                />
            </Box>
            <Box marginTop={5}>
                {`twoday index per ${date.toLocaleDateString('NO', {dateStyle: 'long'})}`}
                <ScoreCharts
                    totalScore={props.indexScoreInfo.totalScore}
                    totalScoreProfile={props.indexScoreInfo.totalScoreProfile}
                    scoreInfoByCategory={props.indexScoreInfo.scoreByCategory}
                />
            </Box>
            <Box paddingLeft={8} paddingRight={8} marginTop={6}>
                <ScoreInfo scoreInfo={props.companyScoreInfo.scoreByCategory} />
            </Box>
        </Card>
    );
};

export const ScorePage: React.FC<{store: AdminState; dispatch: Dispatch<ActionType>}> = ({store, dispatch}) => {
    const {accessToken} = useContext(AccessContext);
    const {id} = useParams<{id: string}>();

    useEffect(() => {
        const fetchData = async () => {
            if (!store.companyLeadInfo) {
                await refreshCompanyLead(parseInt(id), dispatch, accessToken);
            }
            const scoreInfo = await getCompanyLeadScore(id, accessToken);
            if (scoreInfo) {
                dispatch(loadCompanyLeadScore(scoreInfo));
            }
        };
        fetchData();
    }, [accessToken, dispatch, id, store.companyLeadInfo]);

    const history = useHistory();
    const onNavBack = () => {
        history.push(`/admin/${id}`);
    };

    return (
        <Container maxWidth={'xl'} sx={{flexGrow: 1}}>
            <Button
                onClick={onNavBack}
                variant={'text'}
                style={{fontWeight: 100, fontFamily: 'Ubuntu, sans-serif', fontSize: 22, marginTop: 8}}
                startIcon={<ChevronLeftIcon />}
            >
                {store.companyLeadInfo ? store.companyLeadInfo.companyName : ''}
            </Button>
            {store.scoreInfo ? (
                <ScorePageContent
                    companyScoreInfo={store.scoreInfo.company}
                    indexScoreInfo={store.scoreInfo.vismaIndex}
                    completedSurveysCount={
                        store.companyLeadInfo?.participants &&
                        calcCompletedSurveysCount(store.companyLeadInfo.participants)
                    }
                    participantCount={store.companyLeadInfo?.participants?.length}
                />
            ) : (
                <LoadingScreen />
            )}
        </Container>
    );
};
