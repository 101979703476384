import {Configuration} from '@azure/msal-browser';

export const lostPasswordAuthority: string = process.env.REACT_APP_FORGOT_PASSWORD_AUTHORITY ?? '';

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID || '',
        knownAuthorities: (process.env.REACT_APP_KNOWN_AUTHORITIES || '').split(','),
        authority: process.env.REACT_APP_AUTHORITY || '',
        redirectUri: process.env.REACT_APP_REDIRECT_URI || '',
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['openid', 'profile', 'offline_access', msalConfig.auth.clientId],
};

export const authTokenRequest = {
    scopes: [
        'openid',
        'offline_access',
        ...(process.env.REACT_APP_TOKEN_SCOPES ? process.env.REACT_APP_TOKEN_SCOPES.split(';') : []),
    ],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
