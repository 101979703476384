import React from 'react';
import {styled, Typography, useTheme, Box, Container} from '@mui/material';
import {getParagraphs, useLanguage} from '../../Language/LangString';
import {DefaultAppLayout} from '../Layout';
import {CTAButton} from '../Button';
import {useHistory} from 'react-router-dom';
import illustration from '../../assets/DigitalModenhetsanalyse_1.png';

const CContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '32px',
    marginBottom: '32px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
    },
}));

export const ConfirmRegistrationPage: React.FC = () => {
    const theme = useTheme();
    const history = useHistory();
    const {getLangString} = useLanguage();

    const handleButtonClick = () => {
        history.push('/initialQ');
    };
    return (
        <DefaultAppLayout>
            <CContainer maxWidth={'lg'}>
                <Box sx={{display: 'flex'}}>
                    <Box
                        sx={{
                            justifySelf: 'flex-end',
                            marginRight: '40px',
                            borderRadius: '25px',
                            [theme.breakpoints.down('sm')]: {
                                display: 'none',
                            },
                        }}
                    >
                        <img src={illustration} alt={'Illustration'} style={{width: 450, borderRadius: '25px'}} />
                    </Box>
                    <Box maxWidth={560}>
                        <Typography variant={'h2'} style={{fontWeight: 'normal', marginBottom: 40}}>
                            {getLangString('REGISTRATION_CONFIRMATION_HEADER')}
                        </Typography>
                        {getParagraphs(getLangString('REGISTRATION_CONFIRMATION_TEXT'))}
                        <Box sx={{mt: 5}}>
                            {/*TODO: update button text*/}
                            <CTAButton
                                text={getLangString('STRENGTH_PROFILE_BUTTON')}
                                onClick={handleButtonClick}
                            />{' '}
                        </Box>
                    </Box>
                </Box>
            </CContainer>
        </DefaultAppLayout>
    );
};
