import React, {useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import {MainPage} from './MainPage';
import {SurveyPage} from './SurveyPage';
import {adminReducer, AdminState} from '../../store/adminReducer';
import {useMsal} from '@azure/msal-react';
import {InteractionRequiredAuthError} from '@azure/msal-browser';
import {authTokenRequest} from '../../authConfig';
import {LoadingScreen} from '../LoadingScreen';
import {ScorePage} from './ScorePage';

const initialState: AdminState = {
    companyLeads: undefined,
    companyLeadInfo: undefined,
    statistics: undefined,
    leadsFilter: 'ALL',
};

export const AccessContext = React.createContext<{accessToken: string; idTokenClaims: {oid: string} | undefined}>({
    accessToken: '',
    idTokenClaims: undefined,
});

export const AdminRoutes: React.FC = () => {
    const [store, dispatch] = React.useReducer(adminReducer, initialState);
    const {instance, accounts} = useMsal();
    const [accessToken, setAccessToken] = useState<string>();
    const [idTokenClaims, setIdTokenClaims] = useState<{oid: string} | undefined>();

    React.useEffect(() => {
        const requestAccessToken = () => {
            const request = {
                ...authTokenRequest,
                account: accounts[0],
            };
            // Silently acquires an access token which is then attached to a request
            instance
                .acquireTokenSilent(request)
                .then((response) => {
                    setAccessToken(response.accessToken);
                    setIdTokenClaims(response.idTokenClaims as {oid: string});
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        // fallback to interaction when silent call fails
                        return instance.acquireTokenRedirect(request);
                    }
                });
        };
        requestAccessToken();
    }, [accounts, instance]);

    if (!accessToken) {
        return <LoadingScreen />;
    }

    return (
        <AccessContext.Provider value={{accessToken, idTokenClaims}}>
            <Switch>
                <Route path={'/admin/:id/score'}>
                    <ScorePage store={store} dispatch={dispatch} />
                </Route>
                <Route path={'/admin/:id/survey'} component={SurveyPage} />
                <Route path={'/admin/:id?'}>
                    <MainPage store={store} dispatch={dispatch} />
                </Route>
            </Switch>
        </AccessContext.Provider>
    );
};
