import React from 'react';
import {Box, useTheme} from '@mui/material';
import {ScoreChart} from './ScoreChart';

type Props = {
    headerText: string;
    score: number;
    chartColor: string;
    chartSize?: number;
};
type PropsInitialScore = Props & {
    text: string;
};

export const ResultScoreInitialSurvey: React.FC<PropsInitialScore> = ({
    score,
    chartColor,
    headerText,
    text,
    chartSize,
}) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '16px',
                [theme.breakpoints.up('sm')]: {flexDirection: 'row-reverse', alignItems: 'center'},
            }}
        >
            <Box
                sx={{
                    margin: '48px auto 32px auto',
                    width: 'fit-content',
                    [theme.breakpoints.up('sm')]: {margin: 0, marginLeft: '32px'},
                }}
            >
                <ScoreChart size={chartSize || 120} strokeWidth={10} score={score} color={chartColor} />
            </Box>
            <Box maxWidth={600}>
                <p>
                    <b>{headerText}</b>
                </p>
                <p>{text}</p>
            </Box>
        </Box>
    );
};

type PropsMainScore = Props & {
    categoryText: string;
    scoreHeaderText: string;
    scoreBodyText: string;
};
export const ResultScoreMainSurvey: React.FC<PropsMainScore> = (props) => {
    return (
        <div>
            <Box style={{margin: '48px auto 32px auto', width: 'fit-content'}}>
                <ScoreChart
                    size={props.chartSize || 120}
                    fontSize={'1.25rem'}
                    strokeWidth={10}
                    score={props.score}
                    color={props.chartColor}
                />
            </Box>
            <p style={{textAlign: 'center'}}>
                <b>{props.headerText}</b>
            </p>
            <p>{props.categoryText}</p>
            <p>
                {props.scoreHeaderText}. <br />
                {props.scoreBodyText}
            </p>
        </div>
    );
};
