import {ScoreProfile} from '../API/DataModelBackend';
import {ActionType} from './actions';

export type QuestionProps = {
    id: number;
    textNo: string;
    textEn: string;
    answer?: number | null;
};
export type Translations = {textNo: string; textEn: string};
type QuestionGroup = {
    id: number;
    questions: QuestionProps[];
    categoryText?: Translations;
    headerQuestion?: Translations;
    headerQuestionInfo?: Translations;
};
export type Answers = {[key: string]: number | undefined};

export type InitialQuestionnaireProps = {
    surveyID: number;
    questionGroups: QuestionGroup[];
};
export type QuestionnaireProps = {
    reference: string;
    questionGroups: QuestionGroup[];
};

export type ScoreInfo = {
    scoreNumber: number;
    headerText: Translations;
    bodyText: Translations;
    scoreProfile: ScoreProfile;
};

export type MainScoreInfo = {
    scoreNumber: number;
    scoreNumberPercent: number;
    categoryHeaderText: Translations;
    scoreText: {
        headerText: Translations;
        bodyText: Translations;
    };
    scoreProfile: ScoreProfile;
};

export type State = {
    userType?: 'companyLead' | 'participant';
    initialQuestionnaire?: InitialQuestionnaireProps;
    initialScoreInfo?: ScoreInfo;
    questionnaire?: QuestionnaireProps;
    scoreInfo?: MainScoreInfo[];
    answers: Answers;
    companyLeadID: number | undefined;
};
export function surveyReducer(state: State, action: ActionType) {
    switch (action.type) {
        case 'SET_ANSWER':
            return {
                ...state,
                answers: {
                    ...state.answers,
                    [action.payload.qID]: action.payload.answer,
                },
            };
        case 'LOAD_QUESTIONNAIRE_SUCCESS':
            const {questionQategories} = action.payload.questionnaire;
            let transformedAnswers = {};
            let questionGroups: QuestionGroup[] = [];
            questionQategories.forEach((cat) => {
                const groups: QuestionGroup[] = cat.questionSubCategories.map((subCat) => ({
                    id: subCat.id,
                    categoryText: {
                        textNo: cat.perspectiveTextNo,
                        textEn: cat.perspectiveTextEn,
                    },
                    headerQuestion: {
                        textNo: subCat.communicationTextNo,
                        textEn: subCat.communicationTextEn,
                    },
                    headerQuestionInfo: {
                        textNo: subCat.infoTextNo,
                        textEn: subCat.infoTextEn,
                    },
                    questions: subCat.questions,
                }));
                questionGroups.push(...groups);
                cat.questionSubCategories.forEach((subCat) => {
                    return subCat.questions.forEach((q) => {
                        if (q.answer) {
                            transformedAnswers = {
                                ...transformedAnswers,
                                [q.id]: q.answer,
                            };
                        }
                        return transformedAnswers;
                    });
                });
            });

            return {
                ...state,
                questionnaire: {
                    ...state.questionnaire,
                    reference: action.payload.questionnaire.reference,
                    questionGroups,
                },
                answers: transformedAnswers,
            };
        case 'LOAD_INITIAL_QUESTIONNAIRE_SUCCESS':
            const questionnaire = action.payload.initialQuestionnaire;
            return {
                ...state,
                initialQuestionnaire: {
                    surveyID: questionnaire.surveyId,
                    questionGroups: questionnaire.questionQategories[0].questionSubCategories.map((subCat) => ({
                        id: subCat.id,
                        questions: subCat.questions,
                    })),
                },
            };
        case 'SAVE_COMPANY_LEAD_ID':
            return {
                ...state,
                companyLeadID: action.payload,
            };
        case 'SAVE_SCORE_ACTION':
            return {
                ...state,
                initialScoreInfo: {
                    scoreNumber: action.payload.scoreData.score,
                    headerText: {
                        textNo: action.payload.scoreData.scoreText.headerTextNo,
                        textEn: action.payload.scoreData.scoreText.headerTextEn,
                    },
                    bodyText: {
                        textNo: action.payload.scoreData.scoreText.bodyTextNo,
                        textEn: action.payload.scoreData.scoreText.bodyTextEn,
                    },
                    scoreProfile: action.payload.scoreData.scoreText.scoreProfile,
                },
                companyLeadID: action.payload.scoreData.companyLeadId,
            };
        case 'SAVE_MAIN_SCORE_RESULT_ACTION':
            return {
                ...state,
                scoreInfo: action.payload.score.map((s) => ({
                    scoreNumber: s.scoreValue,
                    scoreNumberPercent: s.scoreValuePercent,
                    categoryHeaderText: {
                        textNo: s.categoryTextNo,
                        textEn: s.categoryTextEn,
                    },
                    scoreText: {
                        headerText: {
                            textNo: s.scoreText.headerTextNo,
                            textEn: s.scoreText.headerTextEn,
                        },
                        bodyText: {
                            textNo: s.scoreText.bodyTextNo,
                            textEn: s.scoreText.bodyTextEn,
                        },
                    },
                    scoreProfile: s.scoreText.scoreProfile,
                })),
            };
        default:
            return state;
    }
}
