import React, {Dispatch, useContext} from 'react';
import {Box, Button, CircularProgress, Divider, Paper, Tooltip, Typography, useTheme} from '@mui/material';
import {useLanguage} from '../../Language/LangString';
import {CompanyLeadInfoResponse} from '../../API/DataModelBackend';
import {
    getCompanyLeadInfo,
    sendSurveyEmail,
    setFollowUpContact,
    setInitialContact,
    setWorkshopCompleted,
} from '../../API/apiMethods';
import {
    ActionType,
    loadCompanyLeadInfoAction,
    sendEmailAction,
    setFollowUpContactAction,
    setInitialContactAction,
    setWorkshopCompletedAction,
} from '../../store/actions';
import {Participants} from './Participants';
import {SectorSelectButton} from './SectorSelectButton';
import {AccessContext} from './AdminRoutes';
import {LeadStatusComponent} from './LeadStatusComponent';
import {refreshCompanyLead, refreshCompanyLeads} from './MainPage';
import {useHistory} from 'react-router-dom';
import {LoadingScreen} from '../LoadingScreen';
import {EmailLanguageSelectButton} from './EmailLanguageSelectButton';

const EmailTooltip: React.FC<{text: string}> = ({children, text}) => (
    <Tooltip placement={'bottom'} title={text}>
        <span>{children}</span>
    </Tooltip>
);

type ContentProps = {
    companyInfo: CompanyLeadInfoResponse;
    dispatch: Dispatch<ActionType>;
};
const Content: React.FC<ContentProps> = ({companyInfo, dispatch}) => {
    const accessContext = useContext(AccessContext);
    const history = useHistory();
    const [isSendingEmail, setIsSendingEmail] = React.useState<boolean>(false);
    const [emailError, setEmailError] = React.useState<string>();
    const theme = useTheme();
    const {getLangString} = useLanguage();

    const handleOnInitialClicked = async () => {
        try {
            await setInitialContact(
                {
                    companyLeadId: companyInfo.companyLeadId,
                    initialContactEstablished: !companyInfo.initialContact,
                },
                accessContext.accessToken,
            );
            dispatch(setInitialContactAction(new Date().toString()));
        } catch (e) {}
    };
    const handleOnFollowUpClicked = async () => {
        try {
            await setFollowUpContact(
                {
                    companyLeadId: companyInfo.companyLeadId,
                    workshopInitiated: !companyInfo.followUpCompleted,
                },
                accessContext.accessToken,
            );
            dispatch(setFollowUpContactAction(new Date().toString()));
        } catch (e) {}
    };
    const handleWorkshopCompletedClicked = async () => {
        try {
            await setWorkshopCompleted(
                {
                    companyLeadId: companyInfo.companyLeadId,
                    workshopCompleted: !companyInfo.workshopCompleted,
                },
                accessContext.accessToken,
            );
            dispatch(setWorkshopCompletedAction(new Date().toString()));
        } catch (e) {}
    };

    const {companyLeadId, surveyEmailSent, followUpCompleted, workshopCompleted, initialContact} = companyInfo;
    React.useEffect(() => {
        const refresh = async () => {
            try {
                await refreshCompanyLeads(dispatch, accessContext.accessToken);
                await refreshCompanyLead(companyLeadId, dispatch, accessContext.accessToken);
            } catch (e) {}
        };
        refresh();
    }, [
        accessContext.accessToken,
        surveyEmailSent,
        followUpCompleted,
        workshopCompleted,
        initialContact,
        companyLeadId,
    ]);

    const handleOnShowAnswersClicked = async () => {
        history.push(`/admin/${companyInfo.companyLeadId}/survey`);
    };
    const handleOnShowScoreClicked = async () => {
        history.push(`/admin/${companyInfo.companyLeadId}/score`);
    };
    const sendEmail = async () => {
        try {
            setEmailError('');
            setIsSendingEmail(true);
            await sendSurveyEmail(companyInfo.companyLeadId, accessContext.accessToken);
            dispatch(sendEmailAction(new Date().toString()));
            setIsSendingEmail(false);
            await refreshCompanyLeads(dispatch, accessContext.accessToken);
        } catch (e) {
            setIsSendingEmail(false);
            setEmailError('Kunne ikke sende epost.');
        }
    };
    const isEmailButtonDisabled = () => {
        return companyInfo.initialContact === null || companyInfo.isPrivateSector === null;
    };
    const EmailButton = () => (
        <Button
            variant={!!companyInfo.surveyEmailSent ? 'outlined' : 'contained'}
            color={'primary'}
            disabled={isEmailButtonDisabled()}
            onClick={sendEmail}
            sx={{width: 120, height: 40}}
        >
            {isSendingEmail ? <CircularProgress size={24} /> : getLangString('SEND_EMAIL')}
        </Button>
    );

    return (
        <Paper sx={{flex: 1, ml: 1, p: 4}}>
            <Typography variant={'h3'}>{companyInfo.companyName}</Typography>
            <Box
                sx={{
                    mt: 4,
                    mb: 3,
                    '& button': {
                        mr: 4,
                    },
                }}
            >
                <SectorSelectButton
                    companyLeadId={companyInfo.companyLeadId}
                    isPrivateSector={companyInfo.isPrivateSector}
                    dispatch={dispatch}
                    isClickable={!companyInfo.surveyEmailSent}
                />
            </Box>
            <Box sx={{my: 2}}>
                <LeadStatusComponent
                    companyLead={companyInfo}
                    onInitialClicked={handleOnInitialClicked}
                    onFollowUpClicked={handleOnFollowUpClicked}
                    onWorkshopCompletedClicked={handleWorkshopCompletedClicked}
                />
            </Box>
            <Divider sx={{my: 4}} />
            <Box display={'flex'} justifyContent={'space-between'}>
                <Box>
                    <Typography variant={'h5'}>{getLangString('CONTACT_PERSON')}</Typography>
                    <Box>
                        <div style={{fontSize: '1.25rem'}}>
                            {companyInfo.contactPersonName}, {companyInfo.contactPersonPosition}
                        </div>
                        <div>
                            <Typography sx={{mr: 3}}>{companyInfo.contactPersonEmail}</Typography>
                            {companyInfo.contactPersonPhonenumber}
                        </div>
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                    <EmailLanguageSelectButton
                        language={companyInfo.language}
                        dispatch={dispatch}
                        companyLeadId={companyInfo.companyLeadId}
                    />
                    {isEmailButtonDisabled() ? (
                        <EmailTooltip
                            text={
                                companyInfo.initialContact
                                    ? getLangString('DISABLED_EMAIL_BTN_TOOLTIP_1')
                                    : getLangString('DISABLED_EMAIL_BTN_TOOLTIP_2')
                            }
                        >
                            <EmailButton />
                        </EmailTooltip>
                    ) : (
                        <EmailButton />
                    )}

                    <Box fontSize={14} textAlign={'right'} color={theme.palette.error.main}>
                        {emailError}
                    </Box>
                </Box>
            </Box>
            <Divider sx={{my: 4}} />
            <Participants
                participants={companyInfo.participants || []}
                onShowAnswersClick={handleOnShowAnswersClicked}
                onShowScoreClick={handleOnShowScoreClicked}
            />
        </Paper>
    );
};
type LeadCardProps = {
    companyInfo: CompanyLeadInfoResponse | undefined;
    selectedLead: number | undefined;
    dispatch: Dispatch<ActionType>;
};
export const LeadCard: React.FC<LeadCardProps> = ({companyInfo, selectedLead, dispatch}) => {
    const accessContext = useContext(AccessContext);
    React.useEffect(() => {
        const fetchCompanyLeadInfo = async () => {
            if (selectedLead) {
                try {
                    const leadInfo = await getCompanyLeadInfo(selectedLead, accessContext.accessToken);
                    dispatch(loadCompanyLeadInfoAction(leadInfo));
                } catch (e) {}
            }
        };
        fetchCompanyLeadInfo();
    }, [accessContext.accessToken, selectedLead]);

    if (!companyInfo) {
        return (
            <Paper>
                <LoadingScreen />
            </Paper>
        );
    }
    return <Content companyInfo={companyInfo} dispatch={dispatch} />;
};
