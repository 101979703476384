import React from 'react';
import {Box, Button, ButtonGroup, styled, Theme, Tooltip, useTheme, withStyles} from '@mui/material';
import {range} from '../../toolbox';
import {LangString} from '../../Language/LangString';

const OptionButton = styled(Button)(({theme}) => ({
    flexGrow: 1,
    flexBasis: '100%',
    outlineOffset: -2,
    textTransform: 'none',
    padding: '4px 16px',
    border: '1px solid #E8E8E8',
    borderRight: 0,
    borderRadius: 0,
    fontSize: 14,
    fontWeight: 'normal',
    '&:first-child': {
        borderRadius: '3px 0 0 3px',
    },
    '&:last-child': {
        borderRadius: '0 3px 3px 0',
        borderRight: '1px solid #E8E8E8',
    },
    '&:hover': {
        backgroundColor: 'rgba(255,132,57,0.35)',
        border: '1px solid #E8E8E8',
    },
    maxWidth: '150px',
    [theme.breakpoints.down('lg')]: {
        padding: '0 4px',
        height: '100%',
    },
}));

const CustomButtonGroup = styled(ButtonGroup)({
    display: 'flex',
    width: '100%',
});

type OptionProps = {
    value: number;
    text: string;
    onClick: (value: number) => void;
    isSelected: boolean;
};

const Option: React.FC<OptionProps> = (p) => {
    const setSelected = () => p.onClick(p.value);
    const theme = useTheme();
    return (
        <OptionButton
            sx={{
                backgroundColor: p.isSelected ? 'rgba(255,132,57,0.5)' : '#262626',
                outline: p.isSelected ? '2px solid ' + theme.palette.accent.main : 'none',
                color: 'text.primary',
            }}
            onClick={setSelected}
            disableRipple={true}
        >
            {p.text}
        </OptionButton>
    );
};
const SpecialOption: React.FC<OptionProps> = (p) => {
    const setSelected = () => p.onClick(p.value);
    const theme = useTheme();
    return (
        <OptionButton
            sx={{
                background: p.isSelected ? 'rgba(255,132,57,0.5)' : '#262626',
                outline: p.isSelected ? '2px solid ' + theme.palette.accent.main : 'none',
                ml: {xs: 1, md: 3},
                color: 'text.primary',
                '&:first-child, &:last-child': {
                    borderRadius: '3px',
                },
            }}
            onClick={setSelected}
            disableRipple={true}
        >
            {p.text}
        </OptionButton>
    );
};

export const AnswerOptions: React.FC<{
    questionID: number;
    setAnswer: (option: number) => any;
    selectedOption?: number;
    withUnsureOption?: boolean;
}> = ({questionID, setAnswer, selectedOption, withUnsureOption}) => {
    const optionText: Record<number, string> = {
        1: LangString('ANSWER_ALT_1'),
        2: LangString('ANSWER_ALT_2'),
        3: LangString('ANSWER_ALT_3'),
        4: LangString('ANSWER_ALT_4'),
        5: LangString('ANSWER_ALT_5'),
    };
    const options = [...range(1, 5)].map((num) => {
        return (
            <Option
                key={questionID + num}
                value={num}
                text={optionText[num]}
                aria-label={optionText[num]}
                onClick={setAnswer}
                isSelected={selectedOption === num || false}
            />
        );
    });

    return (
        <Box display={'flex'}>
            <CustomButtonGroup color='primary' aria-label='Svaralternativer'>
                {options}
            </CustomButtonGroup>
            {withUnsureOption && (
                <Box display={'flex'} flexGrow={0}>
                    <Tooltip
                        title={LangString('UNSURE_TOOLTIP')}
                        placement={'top-start'}
                        enterDelay={1500}
                        leaveDelay={200}
                    >
                        <span>
                            <SpecialOption
                                value={-1}
                                text={LangString('UNSURE')}
                                onClick={setAnswer}
                                isSelected={selectedOption === -1}
                            />
                        </span>
                    </Tooltip>
                </Box>
            )}
        </Box>
    );
};
