import {Box, CircularProgress} from '@mui/material';
import React from 'react';

export const LoadingScreen: React.FC<{text?: string}> = ({text}) => {
    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            flex={1}
            width={'100%'}
        >
            <p>{text}</p>
            <CircularProgress />
        </Box>
    );
};
