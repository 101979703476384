import {
    AnswersInitialBE,
    AnswersMainSurvey,
    CompanyLeadInfoResponse,
    CompanyLeadsResponse,
    CompanySurvey,
    RegisterCompanyLeadBE,
    RegisterParticipants,
    MainScoreResultResponse,
    SetInitialContactDtoBE,
    SetSectorBE,
    Survey,
    ReferenceDto,
    WorkshopInitiatedDto,
    WorkshopCompletedDto,
    StatisticsDto,
    ChangeEmailLanguageDto,
    CompanyTotalScoreDto,
    CompanyLeadId,
    ScoreResponse,
} from './DataModelBackend';

const baseURL = process.env.REACT_APP_URL_APPSERVICE;

function _fetch(url: string, config: any) {
    return fetch(url, config).then((resp) => {
        if (!resp.ok) {
            throw new Error(resp.statusText);
        }
        if (resp.status === 204) {
            return new Promise((resolve) => resolve(null));
        }
        return resp.json();
    });
}

function get<T>(url: string): Promise<T> {
    return _fetch(url, {
        method: 'GET',
        credentials: 'omit',
    });
}

function getWithToken<T>(url: string, accessToken: string): Promise<T> {
    return _fetch(url, {
        method: 'GET',
        credentials: 'omit',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
}

function put<T>(url: string, data: T): Promise<any> {
    return _fetch(url, {
        method: 'PUT',
        credentials: 'omit',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data),
    });
}

function putWithToken<T>(url: string, data: T, accessToken: string): Promise<T> {
    return _fetch(url, {
        method: 'PUT',
        credentials: 'omit',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
    });
}

function post<T>(url: string, data: T): Promise<any> {
    return _fetch(url, {
        method: 'POST',
        credentials: 'omit',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

function postWithToken<T>(url: string, accessToken: string, data?: T): Promise<any> {
    return _fetch(url, {
        method: 'POST',
        credentials: 'omit',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
    });
}

export const getInitialSurvey = async () => {
    return await get<Survey>(`${baseURL}/api/survey/initial`);
};
export const sendAnswersInitialSurvey = async (answers: AnswersInitialBE): Promise<ScoreResponse> => {
    return await post<AnswersInitialBE>(`${baseURL}/api/survey/initial`, answers);
};
export const getCompanySurvey = async (reference: string) => {
    return await get<CompanySurvey>(`${baseURL}/api/survey/reference/${reference}`);
};
export const postSurveyAnswers = async (answerData: AnswersMainSurvey): Promise<MainScoreResultResponse> => {
    return await post(`${baseURL}/api/survey/answer`, answerData);
};
export const registerUser = async (userData: RegisterCompanyLeadBE): Promise<CompanyLeadId> => {
    return await post<RegisterCompanyLeadBE>(`${baseURL}/api/survey/savecompanylead`, userData);
};

export const getCompanyLeads = async (accessToken: string) => {
    return await getWithToken<CompanyLeadsResponse>(`${baseURL}/api/companyleads`, accessToken);
};
export const getCompanyLeadInfo = async (id: number, accessToken: string) => {
    return await getWithToken<CompanyLeadInfoResponse>(`${baseURL}/api/companyleads/${id}`, accessToken);
};

export const getCompanyLeadScore = async (id: string, accessToken: string) => {
    return await getWithToken<CompanyTotalScoreDto>(`${baseURL}/api/companyleads/score/${id}`, accessToken);
};

export const getSurvey = async (id: number): Promise<Survey> => {
    return await get<Survey>(`${baseURL}/api/survey/${id}`);
};

export const setCompanySector = async (data: SetSectorBE, accessToken: string) => {
    return await putWithToken<SetSectorBE>(`${baseURL}/api/companyleads/setsector`, data, accessToken);
};
export const setEmailLanguage = async (data: ChangeEmailLanguageDto, accessToken: string) => {
    return await putWithToken<ChangeEmailLanguageDto>(`${baseURL}/api/companyleads/changelanguage`, data, accessToken);
};

export const setInitialContact = async (data: SetInitialContactDtoBE, accessToken: string): Promise<any> => {
    return await putWithToken<any>(`${baseURL}/api/companyleads/setinitialcontact`, data, accessToken);
};
export const setFollowUpContact = async (data: WorkshopInitiatedDto, accessToken: string): Promise<any> => {
    return await putWithToken<any>(`${baseURL}/api/companyleads/workshopinitiated`, data, accessToken);
};
export const setWorkshopCompleted = async (data: WorkshopCompletedDto, accessToken: string): Promise<any> => {
    return await putWithToken<any>(`${baseURL}/api/companyleads/workshopcompleted`, data, accessToken);
};

export const postParticipants = async (data: RegisterParticipants): Promise<ReferenceDto> => {
    return await post<RegisterParticipants>(`${baseURL}/api/companysurvey`, data);
};

export const sendSurveyEmail = async (id: number, accessToken: string) => {
    return await postWithToken(`${baseURL}/api/companyleads/sendemail/${id}`, accessToken);
};

export const checkIfParticipantsExist = async (companyLeadId: string): Promise<ReferenceDto | null> => {
    return await get<ReferenceDto | null>(
        `${baseURL}/api/companysurvey/particiantsexists?companyLeadId=${companyLeadId}`,
    );
};

export const getStatistics = async (accessToken: string) => {
    return await getWithToken<StatisticsDto>(`${baseURL}/api/companyleads/statistics`, accessToken);
};
