import React, {Dispatch} from 'react';
import {Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from '@mui/material';
import {ActionType, setLeadsFilterAction} from '../../store/actions';
import {FilterList} from '@mui/icons-material';

export type LeadsFilter = 'ALL' | 'MINE';
type Props = {
    currentFilter: LeadsFilter;
    dispatch: Dispatch<ActionType>;
};
export const FilterLeadsButton: React.FC<Props> = (props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);
    const handleClose = () => setOpen(false);

    const setFilterAll = () => {
        props.dispatch(setLeadsFilterAction('ALL'));
        handleClose();
    };
    const setFilterMine = () => {
        props.dispatch(setLeadsFilterAction('MINE'));
        handleClose();
    };
    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleToggle}
                startIcon={<FilterList />}
                size={'small'}
                style={{fontWeight: 'normal'}}
            >
                {props.currentFilter === 'ALL' ? 'Alle leads' : 'Mine leads'}
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open}>
                                    <MenuItem onClick={setFilterAll}>Alle</MenuItem>
                                    <MenuItem onClick={setFilterMine}>Mine</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
