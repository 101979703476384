export const NO = {
    MAIN_HEADER_TEXT: 'Digital modenhetsanalyse',
    LANDING_PAGE_HEADER_TEXT__format: 'Kjenner du virksomhetens digitale styrker og svakheter?',
    LANDING_PAGE_TEXT_HEADER_1: 'Hva får dere ut av analysen?',
    LANDING_PAGE_TEXT_SECTION_1_1:
        'En grundig gjennomgang av virksomhetens digitale modenhet basert på deres ' +
        'egenvurdering. Dere får i tillegg en pekepinn på hvordan veien videre kan lukke gap og utvikle flere ' +
        'digitale forretningsprosesser i hele virksomheten.',
    LANDING_PAGE_TEXT_SECTION_1_2: 'Det er helt kostnadsfritt å ta vår digitale modenhetsanalyse.',
    LANDING_PAGE_TEXT_HEADER_2: 'Hva er bedriftens styrker og svakheter?',
    LANDING_PAGE_TEXT_SECTION_2:
        'Vi anbefaler virksomheter i alle størrelser og modenhet å ta en gjennomgang av deres digitale status for ' +
        'å bli mer bevisste på styrker og svakheter. Vår digitale modenhetsanalyse setter virksomhetens situasjon i ' +
        'perspektiv.\n' +
        ' \n' +
        'Du kan be inntil 10 nøkkelpersoner om å bidra ved å svare på spørsmål. Ved at flere ulike personer, fra ' +
        'for eksempel ledergruppe eller som har andre viktige funksjoner, blir ulike hypoteser rundt digitalisering, ' +
        'teknologianvendelse og verdikjedeforståelse målt.',
    LANDING_PAGE_TEXT_HEADER_3: 'Analyserer bedriftens digitale tilstand',
    LANDING_PAGE_TEXT_SECTION_3:
        'Analysen kartlegger hvordan virksomheten er organisert, hvordan produkt og tjenester presenteres og ikke ' +
        'minst hvordan de oppleves for målgruppen. Når spørsmålene er besvart får dere et godt overblikk over deres ' +
        'styrker og hvor dere har potensiale til å utvikle dere videre. Vi hjelper dere gjerne med å finne ut hvordan ' +
        'dere kan benytte disse mulighetene og legge til rette for videre utvikling.',
    INTRO_TEXT_BASE_Q:
        'Digital styrke og modenhet er ingen selvfølge; det er en tilstand der hele virksomheten ' +
        'anvender digitale arbeidsmåter og teknologier for å forbedre prosesser, engasjere arbeidsstyrken og drive ' +
        'frem nye produkt og tjenester. \n Kjenner du virksomhetens digitale styrker og svakheter?',
    INTRO_TEXT_Q:
        'Takk for at du ønsker å gjennomføre en digital modenhetsanalyse!\n' +
        ' \n' +
        'Hensikten med kartleggingen er å gi en grunnleggende forståelse av virksomhetens digitaliseringstilstand. ' +
        'Digital styrke og modenhet er ingen selvfølge; det er en tilstand der hele virksomheten anvender digitale ' +
        'arbeidsmåter og teknologier for å forbedre prosesser, engasjere arbeidsstyrken og drive frem nye produkt og ' +
        'tjenester.\n' +
        ' \n' +
        'Når den digitale styrkeprofilen er identifisert og visualisert, inviterer vi til en fasilitert workshop der ' +
        'vi sammen kartlegger de mest åpenbare mulighetsområdene nærmere. Veien videre bygges på en felles forståelse ' +
        'av virkeligheten.',
    INTRO_TEXT_Q_REGISTER_PARTICIPANTS:
        'Vennligst registrer opptil 10 andre i din virksomhet som bør svare på ' +
        'undersøkelsen. Det er kun disse som kan delta.',
    TAKE_SURVEY: 'Ta testen her!',
    TAKE_SURVEY_ALT2: 'Lagre og gå til spørreundersøkelse',
    REGISTER_PARTICIPANTS: 'Registrer deltakere',
    REGISTRATION_CONFIRMATION_HEADER: 'Takk for din interesse for digital modenhet!',
    REGISTRATION_CONFIRMATION_TEXT:
        'Du vil straks motta en e-post som gir deg tilgang til vår portal. Der har du muligheter til å invitere ' +
        'inntil 10 nøkkelpersoner som du ønsker skal gjennomføre analysen.\n' +
        ' \n' +
        'Analysen består av 50 spørsmål basert innenfor tre nøkkelområder, og tar ca. 20 minutter å gjennomføre. Den ' +
        'digitale modenhetsanalysen vil gi dere en god oversikt over virksomhetens digitale styrker og muligheter ' +
        'for videre oppfølgning.\n' +
        ' \n' +
        'Ønsker du allerede idag å få en indikasjon på din digitale styrkeprofil kan du raskt få dette gjennom de ' +
        'fem spørsmålene på neste side. Merk at styrkeprofil er ikke det samme som digital modenhet.\n' +
        ' \n' +
        'Ta kontakt om du har spørsmål, eller ønsker å diskutere hvordan vi sammen kan gjøre virksomheten deres ' +
        'mer digitalt moden.',
    STRENGTH_PROFILE_BUTTON: 'Test din digitale styrkeprofil',
    LOADING_QUESTIONNAIRE: 'Laster spørreundersøkelse',
    LOADING_SCORE: 'Laster resultat',
    ANSWER_ALT_1: 'Helt uenig',
    ANSWER_ALT_2: 'Delvis uenig',
    ANSWER_ALT_3: 'Nøytral',
    ANSWER_ALT_4: 'Delvis enig',
    ANSWER_ALT_5: 'Helt enig',
    UNSURE: 'Usikker',
    UNSURE_TOOLTIP: 'Valgmulighet om du ikke kjenner til hvordan dere presterer på dette området!',
    NEXT: 'Neste',
    PREVIOUS: 'Forrige',
    DONE: 'Ferdig',
    CONTINUE: 'Fortsett',
    COMPANY_NAME: 'Navn på virksomhet',
    PERSON_NAME: 'Kontaktperson',
    POSITION: 'Stilling',
    PHONE: 'Telefon',
    EMAIL: 'Epost',
    CONTACT_ME: 'Kontakt meg',
    SUBMIT_CONTACT_FORM_BUTTON: 'Start analysen her!',
    CONTACT_FORM_HEADER_TEXT: 'Kontaktperson for gjennomføring',
    DIGITAL_PROFILE: 'Digital styrkeprofil',
    GDPR_LABEL:
        'JA, jeg gir samtykke til behandling av mine personopplysninger for å kartlegge mine interesser som ' +
        'beskrevet her og er kjent med at samtykket kan trekkes tilbake når som helst.',
    PRIVACY_STATEMENT: 'Personvernerklæring',
    GDPR_TEXT_MAIN_SURVEY__format:
        'Digital modenhetsanalyse gjennomføres av twoday og har til hensikt å kartlegge din ' +
        'organisasjons bruk av teknologi og digitale verktøy.\n' +
        'Du er invitert inn for å gi dine vurderinger og registrerte data blir utelukkende lagret for å følge opp din ' +
        'virksomhet. Dine svar blir deretter anonymisert for statistikkformål av twoday. Dine kontaktdata benyttes for ' +
        'å sende deg kvittering på gjennomført kartlegging. Når virksomheten har fullført kartleggingen, vil en ' +
        'rådgiver fra twoday følge opp din virksomhet for å presentere samlede resultater. Dine svar vil her være ' +
        'anonymisert. Personopplysningene behandles i samsvar med vår %href_start%personvernerklæring%href_end%.',
    GDPR_LABEL_MAIN_SURVEY:
        'Ja, jeg samtykker at mine persondata og registrerte data kan benyttes som beskrevet ' +
        'over, og er kjent med at samtykket kan trekkes tilbake når som helst.',
    RESULT_INITIAL_Q_TEXT: 'Takk for din interesse, du vil snart motta e-post til portalen.',
    RESULT_PAGE_CONFIGURATION:
        'Basert på din vurdering av effekter av digitalisering, hvordan dere samarbeider med andre og ' +
        'hvor gode prosesser som er innarbeidet, gir dine tilbakemeldinger følgende oppsummering:',
    RESULT_PAGE_OFFERING:
        'Basert på din vurdering rundt status for digitaliserte tilbud og hvordan de presenteres, gir ' +
        'dine tilbakemeldinger følgende oppsummering:',
    RESULT_PAGE_EXPERIENCE:
        'Basert på dine vurderinger rundt digitalt servicenivå, digital tilstedeværelse og opplevd ' +
        'rennommé, gir dine tilbakemeldinger følgende oppsummering:',
    RESULT_PAGE_TEXT:
        'Takk for dine bidrag!\n' +
        ' \n' +
        'Vi har svært gode erfaringer med å følge opp en digital modenhetsanalyse med en fokusert workshop.\n' +
        'Vi vil derfor ta kontakt og invitere dere til en slik gjennomgang av resultatene om kort tid. Gjennomgangen ' +
        'er en øvelse for å forstå hva hovedfunnene i analysen kan by på av muligheter for dere fremover.',
    //-- Admin --
    CONTACT_PERSON: 'Kontaktperson',
    PARTICIPANTS_QUESTIONNAIRE: 'Deltakere spørreundersøkelse',
    ANSWERED: 'Besvart',
    NOT_ANSWERED: 'Ikke besvart',
    SEND_EMAIL: 'Send epost',
    DISABLED_EMAIL_BTN_TOOLTIP_1: 'Du må velge sektor før du kan sende eposten',
    DISABLED_EMAIL_BTN_TOOLTIP_2: 'Du må trykke på "Initiell Samtale" før du kan sende eposten',
};

export type TranslationKeys = keyof typeof NO;
export const EN = {
    MAIN_HEADER_TEXT: 'Digital maturity analysis',
    LANDING_PAGE_HEADER_TEXT__format:
        "Do you know your company's digital %decorate%strengths%decorate% and %decorate%weaknesses%decorate%?",
    LANDING_PAGE_TEXT_HEADER_1: 'What do you get out of the analysis?',
    LANDING_PAGE_TEXT_SECTION_1_1:
        "You will receive an in-depth review into your company's digital maturity based on your own self-assessment. " +
        'From the analysis we will provide you with the data and insight to allow you to analyse and develop your ' +
        "company's digital future and how to close gaps and start developing digital processes throughout the " +
        'business.',
    LANDING_PAGE_TEXT_SECTION_1_2:
        'Digital maturity analysis is free, so why not take this opportunity and start improving today?',
    LANDING_PAGE_TEXT_HEADER_2: "What are the company's strengths and weaknesses?",
    LANDING_PAGE_TEXT_SECTION_2:
        'We invite companies of any size and maturity to review their digital status to identify their strengths ' +
        'and weaknesses. Our digital maturity analysis will provide you with the information to identify your ' +
        "company's digital maturity situation and provide you with perspective to identify opportunities and " +
        'obstacles.\n' +
        ' \n' +
        'From our portal you can invite up to 10 of your key individuals from your organisation to contribute by ' +
        'answering questions. By having a wide selection of staff from key areas and disciplines within your company ' +
        'we are able to measure hypotheses around digitization, technology use and value chain understanding.',
    LANDING_PAGE_TEXT_HEADER_3: 'Analyzes the digital state of the company',
    LANDING_PAGE_TEXT_SECTION_3:
        'The analysis maps how your business is organised, how products and services are presented and not least, ' +
        'how they are experienced for the target group. Once the self assessment has been completed and submitted, ' +
        "you will receive an assessment of your company's digital state. This will provide you with the opportunity " +
        'to identify your strengths and identify key areas with potential. We are happy to help you find out how you ' +
        'can use this information and what opportunities are available to you and facilitate further improvements.',
    INTRO_TEXT_BASE_Q:
        'Digital strength and maturity are not a matter of course; it is a state where the entire ' +
        'business uses digital working methods and technologies to improve processes, engage the workforce and drive ' +
        'new products and services. \n' +
        "Do you know the company's digital strengths and weaknesses?",
    INTRO_TEXT_Q:
        'Thank you for wanting to conduct a digital maturity analysis! \n ' +
        '\n' +
        "The purpose of the survey is to provide a basic understanding of the company's digitalisation status. " +
        'Digital strength and maturity are not a matter of course; it is a state where the entire business uses ' +
        'digital working methods and technologies to improve processes, engage the workforce and drive new products ' +
        'and services.\n' +
        ' \n' +
        'Once the digital strength profile has been identified and visualized, we invite you to a facilitated ' +
        'workshop where we together map the most obvious areas of opportunity in more detail. The way forward is ' +
        'built on a common understanding of reality.',
    INTRO_TEXT_Q_REGISTER_PARTICIPANTS:
        'Please register up to 10 others in your business who should respond to the ' +
        'survey. Only those you register can participate.',
    TAKE_SURVEY: 'Take the test here!',
    TAKE_SURVEY_ALT2: 'Save and go to questionnaire',
    REGISTER_PARTICIPANTS: 'Register participants',
    LOADING_QUESTIONNAIRE: 'Loading questionnaire',
    LOADING_SCORE: 'Loading score',
    ANSWER_ALT_1: 'Totally disagree',
    ANSWER_ALT_2: 'Somewhat disagree',
    ANSWER_ALT_3: 'Neutral',
    ANSWER_ALT_4: 'Somewhat agree',
    ANSWER_ALT_5: 'Totally agree',
    UNSURE: 'Unsure',
    UNSURE_TOOLTIP: 'Option if you do not know how you perform in this area!',
    CONTINUE: 'Continue',
    NEXT: 'Next',
    PREVIOUS: 'Previous',
    DONE: 'Done',
    DIGITAL_PROFILE: 'Digital strength profile',
    INVITATION:
        'We would like to invite you to a closer digital maturity analysis. Your starting point for digital ' +
        "restructuring is exciting. The digital maturity analysis will give you a good overview of your company's " +
        'digital strengths and opportunities.',
    CONTACT_FORM_HEADER_TEXT: 'Contact person',
    SUBMIT_CONTACT_FORM_BUTTON: 'Start the analysis here!',
    COMPANY_NAME: 'Company name',
    PERSON_NAME: 'Person name',
    POSITION: 'Position',
    PHONE: 'Phone number',
    EMAIL: 'Email',
    GDPR_LABEL:
        'YES, I give consent to the processing of my personal data in order to map my interests as ' +
        'described here and I am aware that the consent can be withdrawn at any time.',
    PRIVACY_STATEMENT: 'Privacy statement',
    GDPR_TEXT_MAIN_SURVEY__format:
        "Digital maturity analysis is performed by twoday and intends to map your organization's " +
        'use of technology and digital tools. \n ' +
        'You are invited to provide your assessments. Registered data is stored exclusively to follow up your ' +
        'business, and your answers are anonymized for statistical purposes by twoday. Your contact details are used ' +
        'to send you a receipt after completing the survey. When the company has completed the survey, a consultant from twoday ' +
        'will follow up your business to present the overall results. The answers presented in the results are anonymized.' +
        'Furthermore, the personal information will be processed in accordance with our %href_start%privacy statement%href_end%.',
    GDPR_LABEL_MAIN_SURVEY:
        'Yes, I agree that my personal data and registered data can be used as described ' +
        'above, and I am aware that the consent can be withdrawn at any time.',
    CONTACT_ME: 'Contact me',
    REGISTRATION_CONFIRMATION_HEADER: 'Thank you for your interest in digital maturity!',
    REGISTRATION_CONFIRMATION_TEXT:
        'You will soon receive an email with instructions on how to access our portal. In the portal you have the ' +
        'opportunity to invite up to 10 key personnel that you would like to contribute towards carrying out the ' +
        'analysis.\n' +
        ' \n' +
        'The analysis consists of 50 questions based on three key areas and takes approx. 20 minutes to complete. ' +
        "The digital maturity results will give you a good overview of the company's digital strengths and " +
        'opportunities for further follow-up.\n' +
        ' \n' +
        'If you already want to get an indication of your digital strength profile today, you can quickly get this ' +
        'through the five questions on the next page. Note that strength profile is not the same or as insightful ' +
        'as digital maturity. \n' +
        ' \n' +
        'We thank you for your time and consideration. Please don’t hesitate to contact us should you have any ' +
        'questions or wish to discuss how we together can make your business more digitally mature.',
    STRENGTH_PROFILE_BUTTON: 'Test your digital strength profile',
    RESULT_INITIAL_Q_TEXT:
        'Thank you for your interest. You will soon receive an email with instructions on how to ' +
        'access our portal.',
    RESULT_PAGE_CONFIGURATION:
        'Based on your assessment of the effects of digitalization, how you collaborate with' +
        ' others and how good processes have been incorporated, your feedback provides the following summary:',
    RESULT_PAGE_OFFERING:
        'Based on your assessment of the status of digitized offers and how they are presented, ' +
        'your feedback provides the following summary:',
    RESULT_PAGE_EXPERIENCE:
        'Based on your assessments of digital service level, digital presence and perceived ' +
        'reputation, your feedback provides the following summary:',
    RESULT_PAGE_TEXT:
        'Thank you for your contributions!\n' +
        '\n' +
        'A good next step after doing a digital maturity analysis is to process the results in a joint workshop.\n' +
        'We will therefore contact you and invite you to such a review of the results shortly. The review is an ' +
        'exercise to understand what the main findings in the analysis can offer in terms of opportunities for you in ' +
        'the future.',
    //-- Admin --
    CONTACT_PERSON: 'Contact person',
    PARTICIPANTS_QUESTIONNAIRE: 'Survey participants',
    ANSWERED: 'Answered',
    NOT_ANSWERED: 'Not answered',
    SEND_EMAIL: 'Send email',
    //TODO: add english translations for admin?
};

export type SupportedLanguage = 'NO' | 'EN';
export const dictionaryList: {[key: string]: {[key: string]: string}} = {NO, EN};

export const languageOptions: Record<SupportedLanguage, string> = {
    NO: 'Norsk',
    EN: 'English',
};
