import React from 'react';
import {Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, styled} from '@mui/material';
import {ChevronLeftIcon, ChevronRightIcon} from '../Icons/ChevronIcons';
import Language from '@mui/icons-material/Language';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {LanguageContext} from '../Language/LanguageProvider';
import {SupportedLanguage, languageOptions} from '../Language/translations';
import {LangString} from '../Language/LangString';

const CustomButton = styled(Button)(({theme}) => ({
    border: 0,
    borderRadius: 50,
    height: 40,
    padding: '0 24px',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
        alignSelf: 'initial',
    },
}));
export const SubmitButton: React.FC<{text: string; disabled?: boolean}> = ({text, disabled}) => {
    return (
        <CustomButton variant={'contained'} color={'primary'} type={'submit'} disabled={disabled}>
            {text}
        </CustomButton>
    );
};
export const CTAButton: React.FC<{text: string; onClick: () => any; disabled?: boolean}> = ({
    text,
    onClick,
    disabled,
}) => {
    return (
        <CustomButton variant={'contained'} color={'primary'} onClick={onClick} disabled={disabled}>
            {text}
        </CustomButton>
    );
};

export const NextButton: React.FC<{onClick: () => any}> = ({onClick}) => {
    return (
        <CustomButton
            onClick={onClick}
            variant={'text'}
            sx={{color: 'primary.main'}}
            endIcon={<ChevronRightIcon color={'primary'} />}
        >
            {LangString('NEXT')}
        </CustomButton>
    );
};
export const PrevButton: React.FC<{onClick: () => any}> = ({onClick}) => {
    return (
        <CustomButton onClick={onClick} sx={{color: 'primary.main'}} startIcon={<ChevronLeftIcon color={'primary'} />}>
            {LangString('PREVIOUS')}
        </CustomButton>
    );
};

export const LanguageSelectButton: React.FC = () => {
    const [open, setOpen] = React.useState(false);
    const {userLanguage, userLanguageChange} = React.useContext(LanguageContext);
    const [selectedLanguage, setSelectedLanguage] = React.useState<SupportedLanguage>(userLanguage);

    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const setNorwegian = () => {
        setSelectedLanguage('NO');
        userLanguageChange('NO');
        handleClose();
    };
    const setEnglish = () => {
        setSelectedLanguage('EN');
        userLanguageChange('EN');
        handleClose();
    };
    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleToggle}
                startIcon={<Language />}
                endIcon={<KeyboardArrowDown />}
                size={'small'}
                variant={'text'}
            >
                {languageOptions[selectedLanguage]}
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open}>
                                    <MenuItem onClick={setNorwegian}>{languageOptions['NO']}</MenuItem>
                                    <MenuItem onClick={setEnglish}>{languageOptions['EN']}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
