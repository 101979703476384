import React from 'react';
import {useMsal} from '@azure/msal-react';
import {Button} from '@mui/material';
import {IPublicClientApplication} from '@azure/msal-browser';

async function handleLogout(instance: IPublicClientApplication) {
    await instance.handleRedirectPromise();
    instance.logoutRedirect().catch((e) => {
        console.error(e);
    });
}

export const SignOutButton: React.FC = () => {
    const {instance} = useMsal();
    const doLogout = () => {
        handleLogout(instance);
    };
    return (
        <Button variant={'text'} onClick={doLogout}>
            Logg ut
        </Button>
    );
};
