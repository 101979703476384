import {
    CompanyLead,
    CompanyLeadInfoResponse,
    CompanyScoreDto,
    ScoreDto,
    ScoreProfile,
    StatisticsDto,
} from '../API/DataModelBackend';
import {ActionType} from './actions';
import {LeadsFilter} from '../Components/Admin/FilterLeadsButton';
import {MainScoreInfo} from './surveyReducer';

export type TotalScoreInfo = {
    totalScore: number;
    totalScoreProfile: ScoreProfile;
    scoreByCategory: MainScoreInfo[];
};

export type AdminState = {
    companyLeads?: CompanyLead[];
    companyLeadInfo?: CompanyLeadInfoResponse;
    scoreInfo?: {
        company: TotalScoreInfo;
        vismaIndex: TotalScoreInfo;
    };
    statistics?: StatisticsDto;
    leadsFilter: LeadsFilter;
};
export function adminReducer(state: AdminState, action: ActionType): AdminState {
    switch (action.type) {
        case 'LOAD_COMPANY_LEAD_INFO_SUCCESS':
            return {
                ...state,
                companyLeadInfo: action.payload.leadInfo,
            };
        case 'LOAD_COMPANY_LEADS_SUCCESS':
            return {
                ...state,
                companyLeads: action.payload.leads,
            };
        case 'SET_COMPANY_SECTOR':
            if (state.companyLeadInfo) {
                return {
                    ...state,
                    companyLeadInfo: {
                        ...state.companyLeadInfo,
                        isPrivateSector: action.payload.data.isPrivateSector,
                    },
                };
            }
            return state;
        case 'SET_EMAIL_LANGUAGE':
            if (state.companyLeadInfo) {
                return {
                    ...state,
                    companyLeadInfo: {
                        ...state.companyLeadInfo,
                        language: action.payload.data.language,
                    },
                };
            }
            return state;
        case 'SET_INITIAL_CONTACT_SUCCESS': {
            if (state.companyLeadInfo) {
                return {
                    ...state,
                    companyLeadInfo: {
                        ...state.companyLeadInfo,
                        initialContact: action.payload,
                    },
                };
            }
            return state;
        }
        case 'SEND_EMAIL_SUCCESS':
            if (state.companyLeadInfo) {
                return {
                    ...state,
                    companyLeadInfo: {
                        ...state.companyLeadInfo,
                        surveyEmailSent: action.payload,
                    },
                };
            }
            return state;
        case 'SET_FOLLOW_UP_CONTACT_SUCCESS': {
            if (state.companyLeadInfo) {
                return {
                    ...state,
                    companyLeadInfo: {
                        ...state.companyLeadInfo,
                        followUpCompleted: action.payload,
                    },
                };
            }
            return state;
        }
        case 'SET_WORKSHOP_COMPLETED_SUCCESS': {
            if (state.companyLeadInfo) {
                return {
                    ...state,
                    companyLeadInfo: {
                        ...state.companyLeadInfo,
                        workshopCompleted: action.payload,
                    },
                };
            }
            return state;
        }
        case 'REFRESH_COMPANY_LEAD_INFO_SUCCESS':
            if (
                state.companyLeadInfo?.companyLeadId &&
                action.payload.leadInfo.companyLeadId !== state.companyLeadInfo?.companyLeadId
            ) {
                return {...state};
            }
            return {
                ...state,
                companyLeadInfo: action.payload.leadInfo,
            };
        case 'LOAD_STATISTICS_SUCCESS':
            return {
                ...state,
                statistics: action.payload,
            };
        case 'SET_LEADS_FILTER':
            return {
                ...state,
                leadsFilter: action.payload,
            };
        case 'LOAD_COMPANY_LEAD_SCORE_INFO': {
            const mapScores = (array: ScoreDto[]) => {
                return array.map((s) => ({
                    scoreNumber: s.scoreValue,
                    scoreNumberPercent: s.scoreValuePercent,
                    categoryHeaderText: {
                        textNo: s.categoryTextNo,
                        textEn: s.categoryTextEn,
                    },
                    scoreText: {
                        headerText: {
                            textNo: s.scoreText.headerTextNo,
                            textEn: s.scoreText.headerTextEn,
                        },
                        bodyText: {
                            textNo: s.scoreText.bodyTextNo,
                            textEn: s.scoreText.bodyTextEn,
                        },
                    },
                    scoreProfile: s.scoreText.scoreProfile,
                }));
            };
            return {
                ...state,
                scoreInfo: {
                    company: {
                        totalScore: action.payload.companyTotalScore.companyTotalScorePercentage,
                        totalScoreProfile: action.payload.companyTotalScore.companyTotalScoreProfile,
                        scoreByCategory: mapScores(action.payload.companyTotalScore.scoreByCategory),
                    },
                    vismaIndex: {
                        totalScore: action.payload.vismaIndexTotal.companyTotalScorePercentage,
                        totalScoreProfile: action.payload.vismaIndexTotal.companyTotalScoreProfile,
                        scoreByCategory: mapScores(action.payload.vismaIndexTotal.scoreByCategory),
                    },
                },
            };
        }
        default:
            return state;
    }
}
