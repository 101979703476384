import React from 'react';
import {dictionaryList, SupportedLanguage, languageOptions} from './translations';

type ContextProps = {
    userLanguage: SupportedLanguage;
    dictionary: {[key: string]: string};
    userLanguageChange: (selected: SupportedLanguage) => any;
};
// create the language context with default selected language
export const LanguageContext = React.createContext<ContextProps>({
    userLanguage: 'NO',
    dictionary: dictionaryList.NO,
    userLanguageChange: (selected: SupportedLanguage) => {},
});

export const LanguageProvider: React.FC = ({children}) => {
    const queryParams = window.location.search;
    const params = new URLSearchParams(queryParams);
    const initialLanguage: SupportedLanguage = params.get('lang') === 'EN' ? 'EN' : 'NO';

    const [userLanguage, setUserLanguage] = React.useState<SupportedLanguage>(initialLanguage || 'NO');

    const provider = {
        userLanguage,
        dictionary: dictionaryList[userLanguage],
        userLanguageChange: (selected: SupportedLanguage) => {
            const newLanguage = languageOptions[selected] ? selected : 'NO';
            setUserLanguage(newLanguage);
        },
    };

    return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>;
};
