import React, {Dispatch} from 'react';
import Container from '@mui/material/Container';
import {Box, styled, Typography, useTheme} from '@mui/material';
import {getParagraphs, useLanguage} from '../../Language/LangString';
import {DefaultAppLayout} from '../Layout';
import illustration from '../../assets/image2.png';
import scoreIllustration from '../../assets/score_illustration_dark.png';
import {RegistrationForm} from './RegistrationForm';
import {ActionType} from '../../store/actions';

const CContainer = styled(Container)({
    display: 'flex',
    alignItems: 'center',
    marginTop: '32px',
});

export const LandingPage: React.FC<{dispatch: Dispatch<ActionType>}> = ({dispatch}) => {
    const theme = useTheme();
    const {getLangString} = useLanguage();

    return (
        <DefaultAppLayout>
            <CContainer maxWidth={'lg'}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        [theme.breakpoints.down('sm')]: {
                            flexDirection: 'column',
                        },
                    }}
                >
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        <Box
                            sx={{
                                maxWidth: 600,
                                marginRight: '48px',
                                [theme.breakpoints.down('sm')]: {
                                    marginRight: 0,
                                    maxWidth: '100%',
                                },
                            }}
                        >
                            <Typography variant={'h1'}>{getLangString('LANDING_PAGE_HEADER_TEXT__format')}</Typography>
                            <Box sx={{my: 4, mt: 4}}>
                                <Box component={'span'} fontWeight={'bold'}>
                                    {getLangString('LANDING_PAGE_TEXT_HEADER_1') + ' '}
                                </Box>
                                {getLangString('LANDING_PAGE_TEXT_SECTION_1_1')}
                                <Box textAlign={'center'}>
                                    <Box
                                        sx={{
                                            margin: '40px auto',
                                            [theme.breakpoints.down('lg')]: {
                                                maxWidth: 480,
                                            },
                                        }}
                                    >
                                        <img src={scoreIllustration} alt={'Illustration'} width={'100%'} />
                                    </Box>
                                </Box>
                                {getLangString('LANDING_PAGE_TEXT_SECTION_1_2')}
                            </Box>
                            <Box sx={{my: 4}}>
                                <Typography variant={'h5'}>{getLangString('LANDING_PAGE_TEXT_HEADER_2')}</Typography>
                                {getParagraphs(getLangString('LANDING_PAGE_TEXT_SECTION_2'))}
                            </Box>
                            <Box sx={{my: 4}}>
                                <Typography variant={'h5'}>{getLangString('LANDING_PAGE_TEXT_HEADER_3')}</Typography>
                                {getParagraphs(getLangString('LANDING_PAGE_TEXT_SECTION_3'))}
                            </Box>
                        </Box>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} flexBasis={400}>
                        <Box
                            sx={{
                                alignSelf: 'end',
                                [theme.breakpoints.down('sm')]: {
                                    display: 'none',
                                },
                            }}
                        >
                            <img src={illustration} alt={'Illustration'} width={'100%'} />
                        </Box>
                        <Typography variant={'h4'} style={{marginBottom: '8px', marginTop: '32px'}}>
                            {getLangString('CONTACT_FORM_HEADER_TEXT')}
                        </Typography>
                        <RegistrationForm dispatch={dispatch} />
                    </Box>
                </Box>
            </CContainer>
        </DefaultAppLayout>
    );
};
