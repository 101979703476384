import React, {ChangeEvent, Dispatch, useContext, useState} from 'react';
import {
    Button,
    ClickAwayListener,
    Grow,
    keyframes,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {SetSectorBE} from '../../API/DataModelBackend';
import {setCompanySector} from '../../API/apiMethods';
import {ActionType, setCompanySectorAction} from '../../store/actions';
import {AccessContext} from './AdminRoutes';

type Props = {
    companyLeadId: number;
    isPrivateSector: boolean | null;
    dispatch: Dispatch<ActionType>;
    isClickable?: boolean;
};
export const SectorSelectButton: React.FC<Props> = (props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const {accessToken} = useContext(AccessContext);

    const updateSector = async (isPrivate: boolean) => {
        const sectorInfo: SetSectorBE = {
            companyLeadId: props.companyLeadId,
            isPrivateSector: isPrivate,
        };
        try {
            await setCompanySector(sectorInfo, accessToken);
            props.dispatch(setCompanySectorAction(sectorInfo));
        } catch (e) {}
        handleClose();
    };
    const handleToggle = () => setOpen((prevOpen) => !prevOpen);
    const handleClose = () => setOpen(false);

    const setPrivate = () => {
        updateSector(true);
    };
    const setPublic = () => {
        updateSector(false);
    };
    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleToggle}
                disabled={!props.isClickable}
                endIcon={<KeyboardArrowDown />}
                size={'small'}
                color={props.isPrivateSector !== null ? 'tertiary' : 'primary'}
                variant={'outlined'}
                sx={{
                    px: 2,
                    fontWeight: 'normal',
                }}
            >
                {props.isPrivateSector
                    ? 'Privat sektor'
                    : props.isPrivateSector === null
                    ? 'Velg sektor'
                    : 'Offentlig sektor'}
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open}>
                                    <MenuItem onClick={setPrivate}>Privat</MenuItem>
                                    <MenuItem onClick={setPublic}>Offentlig</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
