import React from 'react';
import {useMsal} from '@azure/msal-react';
import {IPublicClientApplication} from '@azure/msal-browser';
import {Button} from '@mui/material';
import {loginRequest} from '../../authConfig';

async function handleLogin(instance: IPublicClientApplication) {
    await instance.handleRedirectPromise();

    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.error(e);
        });
    }
}

export const SignInButton: React.FC = () => {
    const {instance} = useMsal();
    const doLogin = () => {
        handleLogin(instance);
    };
    return (
        <Button variant={'contained'} color={'primary'} onClick={doLogin}>
            Logg inn
        </Button>
    );
};
