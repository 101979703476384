import * as React from 'react';
import {surveyReducer, State} from './store/surveyReducer';
import {customTheme} from './theme';
import {MainQuestionnaire} from './Components/Questionnaire/MainQuestionnaire';
import {LanguageProvider} from './Language/LanguageProvider';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {InitialQuestionnaire} from './Components/Questionnaire/InitialQuestionnaire';
import {IntroInitialQuestionnaire} from './Components/Questionnaire/IntroInitialQuestionnaire';
import {IntroQuestionnaire, IntroQuestionnaireMainParticipant} from './Components/Questionnaire/IntroQuestionnaire';
import {ConfirmRegistrationPage} from './Components/Registration/ConfirmRegistrationPage';
import {Index} from './Components/Admin';
import {ParticipantsFormPage} from './Components/Questionnaire/ParticipantsForm';
import {ResultsMainQuestionnaire} from './Components/Questionnaire/ResultsMainQuestionnaire';
import {LandingPage} from './Components/Registration/LandingPage';
import {ResultsInitialQPage} from './Components/Registration/ResultsInitialQPage';
import {CssBaseline, ThemeProvider} from '@mui/material';

function App() {
    const initialState: State = {
        initialQuestionnaire: undefined,
        initialScoreInfo: undefined,
        questionnaire: undefined,
        scoreInfo: undefined,
        answers: {},
        userType: 'participant',
        companyLeadID: undefined,
    };
    const [store, dispatch] = React.useReducer(surveyReducer, initialState);

    return (
        <LanguageProvider>
            <ThemeProvider theme={customTheme}>
                <CssBaseline />
                <Router>
                    <Switch>
                        <Route path={'/admin'}>
                            <Index />
                        </Route>
                        <Route path={'/initialQIntro'}>
                            <LandingPage dispatch={dispatch} />
                        </Route>
                        <Route path={'/initialQ'}>
                            <InitialQuestionnaire store={store} dispatch={dispatch} />
                        </Route>
                        <Route path={'/resultInitialQ'}>
                            <ResultsInitialQPage store={store} />
                        </Route>
                        <Route path={'/confirmation'} component={ConfirmRegistrationPage} />
                        <Route path={'/registerParticipants/:companyLeadId'} component={ParticipantsFormPage} />
                        <Route path={'/questionnaireIntro/:reference'} component={IntroQuestionnaire} />
                        <Route path={'/questionnaireIntro'} component={IntroQuestionnaireMainParticipant} />
                        <Route path={'/questionnaire/:reference/:pageNumb'}>
                            <MainQuestionnaire store={store} dispatch={dispatch} />
                        </Route>
                        <Route path={'/result'}>
                            <ResultsMainQuestionnaire store={store} />
                        </Route>
                        <Route path='/'>
                            <Redirect to={'/initialQIntro'} />
                        </Route>
                    </Switch>
                </Router>
            </ThemeProvider>
        </LanguageProvider>
    );
}

export default App;
