import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import React from 'react';
import {ErrorOutline} from '@mui/icons-material';

type Props = {
    text: string;
    open: boolean;
    handleClose: () => any;
    headerText?: string;
};

export const AlertDialog: React.FC<Props> = ({open, handleClose, text, headerText}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            {headerText ? <DialogTitle id='alert-dialog-title'>{headerText}</DialogTitle> : <></>}
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export const Alert: React.FC<{severity: 'error'; text: string}> = ({severity, text}) => {
    return (
        <Box
            sx={{
                px: 4,
                py: 1,
                display: 'flex',
                alignItems: 'center',
                color: 'rgb(95, 33, 32)',
                backgroundColor: 'rgb(253, 237, 237)',
            }}
        >
            <ErrorOutline color={'error'} />
            <Box ml={2}>{text}</Box>
        </Box>
    );
};
