import {CTAButton, NextButton, PrevButton} from '../Button';
import React, {Dispatch} from 'react';
import {Question} from './Question';
import {Box, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography, useTheme} from '@mui/material';
import {Progress} from '../Progress';
import {Answers, QuestionnaireProps, QuestionProps, State, Translations} from '../../store/surveyReducer';
import {LangString} from '../../Language/LangString';
import {LanguageContext} from '../../Language/LanguageProvider';
import {SupportedLanguage} from '../../Language/translations';
import {Close, Info} from '@mui/icons-material';
import {useWindowSize} from '../../toolbox';
import Container from '@mui/material/Container';
import {useHistory, useParams} from 'react-router-dom';
import {ActionType, loadQuestionnaire, saveMainScoreResultAction, setAnswerAction} from '../../store/actions';
import {getCompanySurvey, postSurveyAnswers} from '../../API/apiMethods';
import {DefaultAppLayout} from '../Layout';
import {AnswersMainSurvey} from '../../API/DataModelBackend';
import {AlertDialog} from '../AlertDialog';
import {LoadingScreen} from '../LoadingScreen';

type SectionProps = {
    dispatch: Dispatch<ActionType>;
    headerQuestion: Translations;
    headerQuestionInfo: Translations;
    categoryText: Translations;
    questions: QuestionProps[];
    pageNumber: number;
    answers: Answers;
};
const getLanguageString = (translations: Translations, userLang: SupportedLanguage) =>
    userLang === 'NO' ? translations.textNo : translations.textEn;
const QuestionsSection: React.FC<SectionProps> = (props) => {
    const {userLanguage} = React.useContext(LanguageContext);
    const theme = useTheme();
    const windowSize = useWindowSize();

    const [open, setOpen] = React.useState(false);
    const handleDialogClose = () => setOpen(false);
    const handleDialogOpen = () => setOpen(true);

    const updateAnswer = (id: number, value: number) => {
        props.dispatch(setAnswerAction(id, value));
    };
    const questions = props.questions.map((question, i) => {
        return (
            <Question
                key={question.id}
                id={question.id}
                questionNumber={i + 1 + (props.pageNumber - 1) * props.questions.length}
                questionText={getLanguageString(question, userLanguage)}
                setAnswer={updateAnswer}
                answer={props.answers[question.id]}
                includeUnsureAnswerOption={true}
            />
        );
    });

    const InfoItemSmallScreen = (
        <>
            <IconButton size={'small'} color={'inherit'} onClick={handleDialogOpen}>
                <Info color={'inherit'} fontSize={'small'} />
            </IconButton>
            <Dialog onClose={handleDialogClose} aria-labelledby='customized-dialog-title' open={open}>
                <DialogTitle>
                    <div style={{fontWeight: 'bold', paddingRight: 16}}>
                        {getLanguageString(props.headerQuestion!, userLanguage)}
                    </div>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: 1,
                            top: 1,
                            color: theme.palette.grey[500],
                        }}
                        autoFocus
                        onClick={handleDialogClose}
                        color='primary'
                    >
                        <Close color={'inherit'} fontSize={'small'} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body1'} gutterBottom>
                        {getLanguageString(props.headerQuestionInfo!, userLanguage)}
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    );
    const InfoItemDefault = (
        <Tooltip
            sx={{
                color: 'rgba(0, 0, 0, 0.87)',
                maxWidth: 400,
                '& .MuiTooltip-popper': {
                    backgroundColor: '#d6efdc',
                    border: '1px solid #dadde9',
                },
                '& p': {
                    fontSize: theme.typography.pxToRem(14),
                },
            }}
            placement={'right-start'}
            title={<Typography gutterBottom>{getLanguageString(props.headerQuestionInfo!, userLanguage)}</Typography>}
        >
            <IconButton size={'small'} color={'inherit'}>
                <Info color={'inherit'} fontSize={'small'} />
            </IconButton>
        </Tooltip>
    );
    const InfoItem = windowSize.width < theme.breakpoints.values.sm ? InfoItemSmallScreen : InfoItemDefault;
    return (
        <>
            <Typography variant={'h3'}>{getLanguageString(props.categoryText!, userLanguage)}</Typography>
            <Box marginTop={2}>
                <Progress totalValue={10} currentValue={props.pageNumber} />
            </Box>
            <Box
                sx={{
                    maxWidth: 900,
                    margin: '0 auto',
                    mt: 7,
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        mt: 4,
                    },
                }}
            >
                <Typography variant={'h4'}>
                    <span style={{verticalAlign: 'middle', marginRight: 8}}>
                        {getLanguageString(props.headerQuestion!, userLanguage)}
                    </span>
                    {InfoItem}
                </Typography>
                {questions}
            </Box>
        </>
    );
};

export const MainQuestionnaire: React.FC<{store: State; dispatch: Dispatch<ActionType>}> = ({store, dispatch}) => {
    const {reference, pageNumb} = useParams<{reference: string; pageNumb: string}>();
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getCompanySurvey(reference);
                dispatch(loadQuestionnaire(result));
            } catch (e) {}
        };
        fetchData();
    }, [reference]);

    let content;
    if (store.questionnaire === undefined) {
        content = <LoadingScreen text={LangString('LOADING_QUESTIONNAIRE') + '...'} />;
    } else {
        content = (
            <Content
                reference={reference}
                pageNumber={parseInt(pageNumb)}
                questionnaire={store.questionnaire}
                dispatch={dispatch}
                answers={store.answers}
            />
        );
    }
    return <DefaultAppLayout>{content}</DefaultAppLayout>;
};

type ContentProps = {
    reference: string;
    pageNumber: number;
    questionnaire: QuestionnaireProps;
    answers: Answers;
    dispatch: Dispatch<ActionType>;
};

const Content: React.FC<ContentProps> = ({reference, pageNumber, questionnaire, answers, dispatch}) => {
    const theme = useTheme();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleClose = () => {
        setDialogOpen(false);
    };
    const history = useHistory();
    const pages = questionnaire.questionGroups.map((g, i) => (
        <QuestionsSection
            dispatch={dispatch}
            pageNumber={i + 1}
            headerQuestion={g.headerQuestion!}
            headerQuestionInfo={g.headerQuestionInfo!}
            categoryText={g.categoryText!}
            questions={g.questions}
            answers={answers}
        />
    ));
    const getSection = () => pages[pageNumber - 1];
    const goToPreviousSection = async () => {
        await saveAnswers(false);
        history.replace(`/questionnaire/${reference}/${pageNumber - 1}`);
    };
    const goToNextSection = async () => {
        await saveAnswers(false);
        history.push(`/questionnaire/${reference}/${pageNumber + 1}`);
    };
    const saveAnswers = async (isSurveyDone: boolean) => {
        const answersMainSurvey: AnswersMainSurvey = {
            reference: reference,
            answers: Object.keys(answers).map((key) => ({questionId: parseInt(key), value: answers[key]})),
            surveyDone: isSurveyDone,
        };
        try {
            const response = await postSurveyAnswers(answersMainSurvey);
            return response;
        } catch (e) {
            console.log('Could not save answers');
        }
    };
    const isAllQuestionsAnswered = () => Object.keys(answers).length === 50;
    const sendAnswers = async () => {
        if (isAllQuestionsAnswered()) {
            try {
                const response = await saveAnswers(true);
                if (response) {
                    dispatch(saveMainScoreResultAction(response));
                }
                history.push('/result');
            } catch (e) {
                throw Error('could not send answers to backend');
            }
        } else {
            handleDialogOpen();
        }
    };
    const ctaButtonString = LangString('DONE'); // hook cant be called conditionally;
    return (
        <Container maxWidth={'lg'}>
            <Box
                sx={{
                    mt: 8,
                    [theme.breakpoints.down('md')]: {
                        mt: 4,
                    },
                }}
            >
                {getSection()}
                <Box display={'flex'} justifyContent={'space-between'} marginTop={5}>
                    {pageNumber === 1 ? <div /> : <PrevButton onClick={goToPreviousSection} />}
                    {pageNumber === pages.length ? (
                        <CTAButton text={ctaButtonString} onClick={sendAnswers} />
                    ) : (
                        <NextButton onClick={goToNextSection} />
                    )}
                </Box>
            </Box>
            <AlertDialog
                open={dialogOpen}
                handleClose={handleClose}
                text={'Du har ikke svart på alle spørsmålene. Du må svare på alt før du kan trykke ferdig'}
            />{' '}
            {/*TODO: change text*/}
        </Container>
    );
};
