import React from 'react';
import {SvgIcon} from '@mui/material';

export const ChevronLeftIcon: React.FC<{color?: string}> = ({color}) => (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
        <rect width='24' height='24' fill='none' />
        <path
            d='M7.4,1.41,5.992,0,0,6l5.992,6L7.4,10.59,2.826,6Z'
            transform='translate(8 6)'
            fill={color || '#222222'}
        />
    </SvgIcon>
);

export const ChevronRightIcon: React.FC<{color?: string}> = ({color}) => (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
        <rect width='24' height='24' fill='none' />
        <path
            d='M1.408,0,0,1.41,4.574,6,0,10.59,1.408,12,7.4,6Z'
            transform='translate(8.6 6)'
            fill={color || '#222222'}
        />
    </SvgIcon>
);
