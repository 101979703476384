import {ScoreProfile} from './API/DataModelBackend';
import {createTheme, SimplePaletteColorOptions} from '@mui/material';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';

declare module '@mui/material/Button' {
    //Define which palette props should be available as Button color
    interface ButtonPropsColorOverrides {
        accent: true;
        tertiary: true;
    }
    //Define which variants should be available
    interface ButtonPropsVariantOverrides {}
}
declare module '@mui/material/styles' {
    interface Palette {
        accent: SimplePaletteColorOptions;
        tertiary: SimplePaletteColorOptions;
    }
    interface PaletteOptions {
        accent?: SimplePaletteColorOptions;
        tertiary?: SimplePaletteColorOptions;
    }
}
export const scoreColors: Record<ScoreProfile, string> = {
    weak: '#E94454',
    partiallyWeak: '#FFA626',
    medium: '#FFDE26',
    partiallyStrong: '#8AC926',
    strong: '#00AC51',
};
const breakpoints = createBreakpoints({});
export const customTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#ff8439',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#daeefd',
            contrastText: '#222222',
        },
        tertiary: {
            main: '#b2b2b2',
            contrastText: '#FFFFFF',
        },
        text: {
            primary: '#FFFFFF',
        },
        accent: {
            main: '#ff8439',
            contrastText: '#FFFFFF',
        },
        info: {
            main: '#00AC51',
        },
        action: {
            // active: '#222222',
        },
    },
    typography: {
        fontFamily: 'Open Sans, Arial, sans-serif',
        fontSize: 16,
        h1: {
            fontSize: '2rem',
            fontFamily: 'Ubuntu, sans-serif',
            fontWeight: 'normal',
        },
        h2: {
            fontSize: '2rem',
            fontFamily: 'Ubuntu, sans-serif',
            fontWeight: 'normal',
        },
        h3: {
            fontSize: '1.75rem',
            fontFamily: 'Ubuntu, sans-serif',
            fontWeight: 'lighter',
        },
        h4: {
            fontSize: '1.375rem',
            fontWeight: 'bold',
        },
        h5: {
            fontSize: '1.125rem',
            fontFamily: 'Ubuntu, sans-serif',
            marginBottom: 8,
        },
        body1: {
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.85rem',
            height: '1.5rem',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: 'Open Sans, Arial, sans-serif',
                    backgroundColor: '#171717',
                    color: '#FFFFFF',
                    minHeight: '100vh',
                    fontSize: 16,
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    textTransform: 'none',
                },
            },
            variants: [
                {
                    props: {
                        variant: 'text',
                    },
                    style: {
                        color: '#FFFFFF',
                    },
                },
            ],
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    // backgroundColor: 'white',
                    // boxShadow: '0px 2px 5px #e0e0e0',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '0.875rem',
                    alignItems: 'flex-start',
                },
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                variant: 'filled',
            },
        },
    },
});
