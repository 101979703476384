import React, {ChangeEvent, SyntheticEvent, useState} from 'react';
import {Box, TextField, Typography} from '@mui/material';
import {LangString} from '../../Language/LangString';
import {range, validateEmail} from '../../toolbox';
import Container from '@mui/material/Container';
import {postParticipants} from '../../API/apiMethods';
import {useHistory, useParams} from 'react-router-dom';
import {RegisterParticipants} from '../../API/DataModelBackend';
import {DefaultAppLayout} from '../Layout';
import {SubmitButton} from '../Button';
import {Alert} from '../AlertDialog';

const FormField: React.FC<{
    handleChange: (e: ChangeEvent<HTMLInputElement>) => any;
    id: number;
    validateEmail: (id: string) => boolean;
}> = ({id, handleChange, validateEmail}) => {
    const [isValidInput, setIsValidInput] = useState(true);
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.value.length > 0 ? setIsValidInput(validateEmail(event.target.name)) : setIsValidInput(true);
    };
    return (
        <Box key={id} display={'flex'} alignItems={'center'} marginBottom={1}>
            <Typography
                sx={{
                    width: 24,
                    textAlign: 'right',
                }}
            >
                {id}.
            </Typography>
            <TextField
                sx={{
                    flex: 1,
                    display: 'flex',
                    ml: 8,
                }}
                variant={'outlined'}
                size={'small'}
                label={LangString('EMAIL')}
                name={id.toString()}
                onChange={handleChange}
                error={!isValidInput}
                helperText={!isValidInput ? 'Invalid input' : ''}
                onBlur={handleBlur}
            />
        </Box>
    );
};

export const ParticipantsFormPage: React.FC = () => {
    const {companyLeadId} = useParams<{companyLeadId: string}>();
    const [participant, setParticipant] = React.useState<{[key: string]: string}>({});
    const [hasInvalidInputs, setHasInvalidInputs] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>();

    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.value;
        setParticipant({
            ...participant,
            [evt.target.name]: value,
        });
    };
    const doValidate = (id: string) => {
        const isValid = validateEmail(participant[id]);
        setHasInvalidInputs(!isValid);
        return isValid;
    };

    const column1 = range(1, 5).map((field) => (
        <FormField key={field} id={field} handleChange={handleChange} validateEmail={doValidate} />
    ));
    const column2 = range(6, 10).map((field) => (
        <FormField key={field} id={field} handleChange={handleChange} validateEmail={doValidate} />
    ));

    const history = useHistory();
    const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!hasInvalidInputs) {
            const participantsInfo: RegisterParticipants = {
                companyLeadId: parseInt(companyLeadId),
                emails: Object.keys(participant).map((key) => participant[key]),
            };
            try {
                const {reference} = await postParticipants(participantsInfo);
                history.push(`/questionnaire/${reference}/1`);
            } catch (e) {
                setError('Could not register participants. Please try again.');
            }
        }
    };
    return (
        <DefaultAppLayout overrideFlexDirection={'column'}>
            {error && <Alert severity={'error'} text={error} />}
            <Container maxWidth={'md'}>
                <Box marginTop={8}>
                    <Box marginBottom={4}>
                        <p>{LangString('INTRO_TEXT_Q_REGISTER_PARTICIPANTS')}</p>
                    </Box>
                    <form onSubmit={handleSubmit}>
                        <Box display={'flex'} marginBottom={5}>
                            <Box flex={'1'} display={'flex'} flexDirection={'column'} marginRight={1}>
                                {column1}
                            </Box>
                            <Box flex={'1'} display={'flex'} flexDirection={'column'}>
                                {column2}
                            </Box>
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <SubmitButton text={LangString('TAKE_SURVEY_ALT2')} />
                        </Box>
                    </form>
                </Box>
            </Container>
        </DefaultAppLayout>
    );
};
