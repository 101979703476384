import {AnswerOptions} from './AnswerOption';
import React, {useState} from 'react';
import {Box, styled, Theme, Typography, useMediaQuery, useTheme, withStyles} from '@mui/material';

const Text = styled(Box)({
    margin: '40px 0 8px 0',
    fontSize: '1rem',
});

type Props = {
    id: number;
    questionNumber: number;
    questionText: string;
    setAnswer: (id: number, answer: number) => any;
    answer: number | undefined;
    includeUnsureAnswerOption?: boolean;
};
export const Question: React.FC<Props> = ({
    id,
    questionNumber,
    questionText,
    setAnswer,
    answer,
    includeUnsureAnswerOption,
}) => {
    const theme = useTheme();
    const [selectedOption, setSelectedOption] = useState<undefined | number>(answer);
    const updateAnswer = (value: number) => {
        setSelectedOption(value);
        setAnswer(id, value);
    };
    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const number = (
        <Box
            sx={{
                width: '56px',
                margin: '40px 0 8px 0',
                textAlign: 'right',
                padding: '0 16px',
                [theme.breakpoints.down('sm')]: {
                    width: 'auto',
                    margin: 0,
                    p: 0,
                    pr: 1,
                },
            }}
        >
            {questionNumber}.
        </Box>
    );
    if (isSmallScreen) {
        return (
            <Box sx={{my: 4}}>
                <Box display={'flex'} alignItems={'flex-start'}>
                    {number}
                    <Typography>{questionText}</Typography>
                </Box>
                <Box sx={{mt: 1}}>
                    <AnswerOptions
                        questionID={id}
                        setAnswer={updateAnswer}
                        selectedOption={selectedOption}
                        withUnsureOption={includeUnsureAnswerOption}
                    />
                </Box>
            </Box>
        );
    }
    return (
        <Box display={'flex'}>
            {number}
            <Box flexGrow={1}>
                <Box sx={{margin: '40px 0 8px 0', maxWidth: includeUnsureAnswerOption ? 'none' : 570}}>
                    <Typography>{questionText}</Typography>
                </Box>
                <AnswerOptions
                    questionID={id}
                    setAnswer={updateAnswer}
                    selectedOption={selectedOption}
                    withUnsureOption={includeUnsureAnswerOption}
                />
            </Box>
        </Box>
    );
};
