import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {EventMessage, EventType, PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {msalConfig, lostPasswordAuthority} from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((message: EventMessage) => {
    if (message.eventType === EventType.LOGIN_FAILURE) {
        console.log('');
        if (message.error?.message?.includes('AADB2C90118')) {
            msalInstance.loginRedirect({
                authority: lostPasswordAuthority,
                scopes: [],
            });
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
