import React, {ReactElement} from 'react';
import {
    AnswerBE,
    Participant,
    QuestionCategory,
    QuestionSubCategory,
    ScoreReferenceIndex,
} from '../../API/DataModelBackend';
import {range} from '../../toolbox';

const colors = ['#FF7E7E', '#FFB07E', '#FFD77E', '#CAE597', '#92CB57', '#DFE0DD'];

const height = 23;
const width = 35;
const gap = 1;
const sectionGap = 8;

interface IDictionary {
    [id: number]: number;
}

const renderBackground = (questionSubCategories: QuestionSubCategory[], questionPoints: IDictionary): any => {
    return questionSubCategories.map((subCategory, s) => {
        return (
            <g key={`s_g_${s}`}>
                {subCategory.questions.map((question, i) => {
                    const array = range(0, 4);
                    return (
                        <g key={`s_g_${s}_${i}`}>
                            {array.map((l) => (
                                <rect
                                    fill={colors[l]}
                                    key={`s_g_${s}_${i}_${l}`}
                                    x={l * width + gap * l}
                                    y={questionPoints[question.id]}
                                    height={height}
                                    width={width}
                                />
                            ))}
                            <rect
                                fill={colors[5]}
                                key={`s_g_${s}_${i}_${5}`}
                                x={5 * width + gap * 6 + 4}
                                y={questionPoints[question.id]}
                                height={height}
                                width={width}
                            />
                        </g>
                    );
                })}
            </g>
        );
    });
};

const renderResults = (participants: Participant[], questionPoints: IDictionary): ReactElement => {
    return (
        <svg y={0} x={0}>
            {participants.filter((p) => p.answers).map((p, i) => renderResultLine(p.answers, questionPoints, i))}
        </svg>
    );
};

const valueToX = (val: number): number => {
    let index = Math.min(val === -1 ? 5 : val - 1, 5);
    return index * width + gap * index + (index === 5 ? 6 : 0);
};

const pointsToPath = (numbers: Array<{x: number; y: number}>): string => {
    return numbers
        .map((n, i) => {
            return `${i === 0 ? 'M' : 'L'} ${n.x} ${n.y}`;
        })
        .join(' ');
};

const renderResultLine = (
    answers: AnswerBE[],
    questionPoints: IDictionary,
    index: number,
    color: string = '#2548A5',
): ReactElement => {
    let points = answers
        .map((a) => {
            let x = valueToX(a.value) + width / 2 - 1;
            let y = questionPoints[a.questionId] + height / 2 - 1;
            return {x, y};
        })
        .filter((p) => !!p.y)
        .sort((a, b) => a.y - b.y);
    let path = pointsToPath(points);
    return (
        <path
            key={`a_path_${index}`}
            d={path}
            strokeLinejoin={'round'}
            strokeLinecap={'round'}
            fill={'none'}
            stroke={color}
            strokeWidth={'2px'}
        />
    );
};

const createQuestionPoints = (questionSubCategories: QuestionSubCategory[]): IDictionary => {
    return questionSubCategories
        .flatMap((subCategory, s) => {
            const sectionHeight = s * subCategory.questions.length * (height + gap) + s * sectionGap;
            return subCategory.questions.flatMap((question, i) => {
                const y = i * height + gap * i + sectionHeight;
                return {id: question.id, y};
            });
        })
        .reduce((a, x) => ({...a, [x.id]: x.y}), {});
};

const calcSVGHeight = (sectionsCount: number) => {
    return (height + gap) * 5 * sectionsCount + sectionGap * (sectionsCount - 1);
};
export const SurveyGraphComponent: React.FC<{
    questionCategory: QuestionCategory;
    participants: Participant[] | null;
    scoreReferenceIndex: ScoreReferenceIndex | undefined;
    showReferenceIndex: boolean;
}> = ({questionCategory, participants, scoreReferenceIndex, showReferenceIndex}) => {
    const questionPoints = createQuestionPoints(questionCategory.questionSubCategories);
    return (
        <svg height={`${calcSVGHeight(questionCategory.questionSubCategories.length)}px`} width={220}>
            {renderBackground(questionCategory.questionSubCategories, questionPoints)}
            {participants && renderResults(participants, questionPoints)}
            {showReferenceIndex &&
                scoreReferenceIndex &&
                renderResultLine(scoreReferenceIndex.answers, questionPoints, 100, 'red')}
        </svg>
    );
};
