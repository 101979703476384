import React from 'react';
import {LanguageContext} from './LanguageProvider';
import {SupportedLanguage, TranslationKeys} from './translations';
import {Translations} from '../store/surveyReducer';
import {Typography} from '@mui/material';

export const getParagraphs = (text: string) => {
    const paragraphs = text.split(' \n');
    return (
        <>
            {paragraphs.map((p, i) => (
                <Typography sx={{mb: 2, mt: 0}} key={i}>
                    {p}
                </Typography>
            ))}
        </>
    );
};

export const LangString = (tID: TranslationKeys) => {
    const languageContext = React.useContext(LanguageContext);

    return languageContext.dictionary[tID] || tID;
};
export const getLangStringFromDict = (tID: TranslationKeys, dict: {[key: string]: string}) => {
    return dict[tID] || tID;
};
/**
 * Hook to get access to language provider for translations
 */
export const useLanguage = () => {
    const languageContext = React.useContext(LanguageContext);
    // method for using translation string from local dictionary (translations.ts)
    const getLangString = (tID: TranslationKeys) => languageContext.dictionary[tID] || tID;
    // method for using translation string fetched from backend
    const getTranslation = (translations: Translations) =>
        languageContext.userLanguage === 'NO' ? translations.textNo : translations.textEn;
    return {getLangString, getTranslation};
};
