import React, {Dispatch, useContext} from 'react';
import {Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {ChangeEmailLanguageDto} from '../../API/DataModelBackend';
import {setEmailLanguage} from '../../API/apiMethods';
import {ActionType, setEmailLanguageAction} from '../../store/actions';
import {AccessContext} from './AdminRoutes';

type Props = {
    companyLeadId: number;
    language: string | undefined;
    dispatch: Dispatch<ActionType>;
};
export const EmailLanguageSelectButton: React.FC<Props> = (props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const {accessToken} = useContext(AccessContext);

    const updateLanguage = async (language: string) => {
        const languageInfo: ChangeEmailLanguageDto = {
            companyLeadId: props.companyLeadId,
            language,
        };
        try {
            await setEmailLanguage(languageInfo, accessToken);
            props.dispatch(setEmailLanguageAction(languageInfo));
        } catch (e) {}
        handleClose();
    };
    const handleToggle = () => setOpen((prevOpen) => !prevOpen);
    const handleClose = () => setOpen(false);

    const setNorwegian = () => {
        updateLanguage('no');
    };
    const setPublic = () => {
        updateLanguage('en');
    };
    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleToggle}
                endIcon={<KeyboardArrowDown />}
                size={'small'}
                variant={'outlined'}
                color={'tertiary'}
                sx={{pl: 2, pr: 1, mb: 1, fontWeight: 'normal', fontSize: '0.875rem', borderColor: 'darkgray'}}
            >
                {props.language === 'no' ? 'Språk i epost: Norsk' : 'Språk i epost: Engelsk'}
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{zIndex: 1000}}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open}>
                                    <MenuItem onClick={setNorwegian}>Norsk</MenuItem>
                                    <MenuItem onClick={setPublic}>Engelsk</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
