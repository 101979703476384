import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {DefaultAppLayout} from '../Layout';
import {styled, Typography, useTheme} from '@mui/material';
import {State} from '../../store/surveyReducer';
import {useLanguage} from '../../Language/LangString';
import {LoadingScreen} from '../LoadingScreen';
import {scoreColors} from '../../theme';
import {ScoreChart} from '../ScoreChart';

const CContainer = styled(Container)({
    display: 'flex',
    alignItems: 'center',
});
export const ResultsInitialQPage: React.FC<{store: State}> = ({store}) => {
    const {getLangString, getTranslation} = useLanguage();
    const theme = useTheme();

    if (store.initialScoreInfo === undefined || !store.companyLeadID) {
        return (
            <DefaultAppLayout>
                <LoadingScreen text={getLangString('LOADING_SCORE') + '...'} />
            </DefaultAppLayout>
        );
    }
    return (
        <DefaultAppLayout>
            <CContainer maxWidth={'md'}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'flex-end',
                        marginTop: '32px',
                        marginBottom: '32px',
                        [theme.breakpoints.down('sm')]: {
                            flexDirection: 'column',
                            marginTop: '32px',
                        },
                    }}
                >
                    <div>
                        <Typography variant={'h2'}>{getLangString('DIGITAL_PROFILE')}</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '16px',
                                [theme.breakpoints.up('sm')]: {flexDirection: 'row-reverse', alignItems: 'center'},
                            }}
                        >
                            <Box
                                sx={{
                                    margin: '48px auto 32px auto',
                                    width: 'fit-content',
                                    [theme.breakpoints.up('sm')]: {margin: 0, marginLeft: '56px'},
                                }}
                            >
                                <ScoreChart
                                    size={130}
                                    strokeWidth={10}
                                    score={store.initialScoreInfo.scoreNumber}
                                    color={scoreColors[store.initialScoreInfo.scoreProfile]}
                                />
                            </Box>
                            <Box maxWidth={600}>
                                <p>
                                    <b>{getTranslation(store.initialScoreInfo.headerText)}</b>
                                </p>
                                <p>{getTranslation(store.initialScoreInfo.bodyText)}</p>
                                <p>{getLangString('RESULT_INITIAL_Q_TEXT')}</p>
                            </Box>
                        </Box>
                    </div>
                </Box>
            </CContainer>
        </DefaultAppLayout>
    );
};
