import React, {Dispatch, useContext} from 'react';
import {getCompanyLeadInfo, getCompanyLeads, getStatistics} from '../../API/apiMethods';
import {
    ActionType,
    loadCompanyLeadsAction,
    loadStatisticsAction,
    refreshCompanyLeadInfoAction,
} from '../../store/actions';
import {Box, Container} from '@mui/material';
import {LeadsList} from './LeadsList';
import {LeadCard} from './LeadCard';
import {AdminState} from '../../store/adminReducer';
import {AccessContext} from './AdminRoutes';
import {useHistory, useParams} from 'react-router-dom';
import {LoadingScreen} from '../LoadingScreen';
import {Statistics} from './Statistics';

export const refreshCompanyLeads = async (dispatch: Dispatch<ActionType>, accessToken: string) => {
    try {
        const leads = await getCompanyLeads(accessToken);
        dispatch(loadCompanyLeadsAction(leads));
    } catch (e) {}
};

export const refreshCompanyLead = async (
    companyLeadId: number,
    dispatch: Dispatch<ActionType>,
    accessToken: string,
) => {
    try {
        const leadInfo = await getCompanyLeadInfo(companyLeadId, accessToken);
        dispatch(refreshCompanyLeadInfoAction(leadInfo));
    } catch (e) {}
};

export const MainPage: React.FC<{store: AdminState; dispatch: Dispatch<ActionType>}> = ({store, dispatch}) => {
    const params = useParams<{id: string}>();
    const accessContext = useContext(AccessContext);
    const history = useHistory();
    const goToSelectedLead = (leadId: number) => history.push(`/admin/${leadId}`);

    React.useEffect(() => {
        const fetchCompanyLeads = async () => {
            try {
                const leads = await getCompanyLeads(accessContext.accessToken);
                dispatch(loadCompanyLeadsAction(leads));
                if (!params.id) {
                    goToSelectedLead(leads[0].companyLeadId);
                }
            } catch (e) {}
        };
        fetchCompanyLeads();
    }, [accessContext.accessToken]);
    React.useEffect(() => {
        const fetchStatistics = async () => {
            try {
                const stats = await getStatistics(accessContext.accessToken);
                dispatch(loadStatisticsAction(stats));
            } catch (e) {}
        };
        fetchStatistics();
    }, [accessContext.accessToken]);
    if (!store.companyLeads) {
        return <LoadingScreen text={'Loading content...'} />;
    }
    return (
        <Container maxWidth={'xl'}>
            {store.statistics && <Statistics stats={store.statistics} />}
            <Box display={'flex'} marginTop={1}>
                <LeadsList
                    leads={store.companyLeads}
                    selectedLead={parseInt(params.id)}
                    setSelectedLead={goToSelectedLead}
                    currentLeadFilter={store.leadsFilter}
                    dispatch={dispatch}
                />
                <LeadCard companyInfo={store.companyLeadInfo} selectedLead={parseInt(params.id)} dispatch={dispatch} />
            </Box>
        </Container>
    );
};
